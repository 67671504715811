import { Input } from "antd";
import React from "react";
import styled, { css } from "styled-components";

import { Colors, Fonts } from "../../config";
import { MontserratBold } from "../../config/fonts";
import Text from "../Text";

const StyledInput = styled(Input).attrs({ className: "inputField" })`
  ${(props) =>
    !props.password
      ? css`
&[type], input {
    background-color: ${props.bgColor ? props.bgColor : Colors.White};
    color: ${props.color ? props.color : "gray"};
    border: 1px solid #D7DBE8;
    border-radius: 8px;
    font-family: "Montserrat" !important;
    height: ${props.type == "email" ? "35px" : "50px"} !important;
}
& .ant-input-disabled{
  color:black;
}
&[type]:hover, input:hover {
  text-decoration:none
  border: none !important;
  box-shadow: none !important;
}
&[type]:focus, input:focus {
  border: none !important;
  box-shadow: none !important;
}
/* * {
  color: grey;
} */
`
      : css`
          & {
            background-color: ${props.bgColor ? props.bgColor : Colors.White};
            color: ${props.color ? props.color : "gray"};
            font-family: "Montserrat" !important;
            border: 1px solid #D7DBE8;
            border-radius: 8px;
            align-items: center;
            height: 50px !important;
          }
          &:before {
            width: 0;
            visibility: hidden;
            content: "\a0";
            line-height: 0;
          }
          input {
            color: gray;
          }
          &:hover {
            text-decoration:none
            border: none !important;
            box-shadow: none !important;
          }
          &:focus,
          &.ant-input-affix-wrapper-focused {
            border: none !important;
            box-shadow: none !important;
          }
          * {
            color: gray;
          }
        `}/* &.lightBg {
            background-color: rgba(255, 255, 255, 0.5);
} */
`;

const Basic = (props) => {
  return props.password ? (
    <div className="flex-column">
      {props.label && (
        <Text.Basic
          text={props.label}
          textAlign={"left"}
          fontSize={props.labelFontSize ? props.labelFontSize : "18"}
          color={Colors.Black}
          fontWeight={props.labelFontWeight ? props.labelFontWeight : "700"}
          fontFamily={
            props.labelFontFamily
              ? props.labelFontFamily
              : Fonts[MontserratBold]
          }
          className={props.labelClassName ? props.labelClassName : "mb-10"}
        />
      )}
      <StyledInput as={Input.Password} {...props}></StyledInput>
      {props.errorText && <Text.Error text={props.errorText} />}
    </div>
  ) : (
    <div className="flex-column">
      {props.label && (
        <Text.Basic
          text={props.label}
          textAlign={"left"}
          fontSize={props.labelFontSize ? props.labelFontSize : "18"}
          color={Colors.Black}
          fontWeight={props.labelFontWeight ? props.labelFontWeight : "700"}
          fontFamily={
            props.labelFontFamily
              ? props.labelFontFamily
              : Fonts[MontserratBold]
          }
          className={props.labelClassName ? props.labelClassName : "mb-10"}
        />
      )}
      <StyledInput {...props}></StyledInput>
      {props.errorText && <Text.Error text={props.errorText} />}
    </div>
  );
};

export default Basic;
