import { combineReducers } from "redux";

import AuthReducer from "./authReducer";
import DashboardReducer from "./dashboardReducer";
import FeedbackReducer from "./feedbackReducer";
import MediaReducer from "./mediaReducer";
import PromoCodeReducer from "./promoCodeReducer";
import UserReducer from "./userReducer";

const RootReducer = combineReducers({
  auth: AuthReducer,
  media: MediaReducer,
  user: UserReducer,
  dashboard: DashboardReducer,
  feedback: FeedbackReducer,
  promoCode: PromoCodeReducer
});

export default RootReducer;
