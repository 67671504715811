import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { FeedbackAction } from "../actions";

export default class FeedbackMiddleware {
  static *GetFeedbacks({ payload, cb }) {
    try {
      const { limit = 10, offset = 0, type = "" } = payload;
      let res = yield ApiCaller.Get(
        `/feedback/list?limit=${limit}&offset=${offset}&feedback_type=${type}`
      );
      if (res.status == 200) {
        yield put(FeedbackAction.getFeedbacksSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(FeedbackAction.getFeedbacksFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(FeedbackAction.getFeedbacksFailure());
    }
  }

  static *DeleteFeedback({ id, cb }) {
    try {
      let res = yield ApiCaller.Delete(`/feedback/delete?feedback_id=${id}`);
      if (res.status == 200) {
        yield put(FeedbackAction.deleteFeedbackSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(FeedbackAction.deleteFeedbackFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(FeedbackAction.deleteFeedbackFailure());
    }
  }

  static *ReplyFeedback({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/feedback/respond`, payload);
      if (res.status == 200) {
        yield put(FeedbackAction.replyFeedbackSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(FeedbackAction.replyFeedbackFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(FeedbackAction.replyFeedbackFailure());
    }
  }
}
