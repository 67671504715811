import { Drawer } from "antd";
import { FiLogOut } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { GrClose } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMedia } from "use-media";
import React, { useState } from "react";

import { AuthAction } from "../../store/actions";
import { Colors, Images } from "../../config";
import styles from "./style";

import "./navHeader.css";

const NavHeader = ({ expandible, collapsed, toggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const isTablet = useMedia({ minWidth: "770px" });
  const isMobile = useMedia({ minWidth: "500px" });
  const title = ((location || {}).pathname || {}).split("/")[1];
  const LSauthName = JSON.parse(localStorage.getItem("authUser"));
  const authName = useSelector((state) => state.auth.user.name);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleLogout = () => {
    dispatch(
      AuthAction.Logout(() => {
        navigate("/login");
      })
    );
  };

  const Hamburger = ({ placement }) => (
    <>
      <div className="nav-header-mobile" onClick={showDrawer}>
        <GiHamburgerMenu
          style={{
            color: Colors.White,
            fontSize: "40px",
            backgroundColor: "#7836ff",
            padding: "10px",
            borderRadius: "5px",
          }}
          className="hamburger-menu"
        />
      </div>
      <Drawer
        closeIcon={false}
        title=""
        placement={placement}
        onClose={onClose}
        open={visible}
        width={isTablet ? 378 : 320}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between", //isMobile ? "flex-start" : "flex-end",
            alignItems: "center",
            paddingLeft: isMobile ? "15px" : "0px",
            paddingRight: isMobile ? "0px" : "15px",
            marginBottom: "20px",
          }}
        >
          {!isMobile ? (
            <>
              <div className="ml-15">
                <img src={Images.LogoWithText} />
              </div>
              <GrClose fontSize={24} onClick={onClose} />
            </>
          ) : (
            <div className="ml-15">
              <img src={Images.LogoWithText} />
            </div>
          )}
        </div>
        <div className="mobile-navbar">
          <ul>
            <li
              className={title === "dashboard" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("dashboard");
              }}
            >
              {location.pathname.includes("dashboard") ? (
                <img
                  src={Images.DashboardSelectedIcon}
                  width={16}
                  style={{ marginRight: "20px" }}
                />
              ) : (
                <img src={Images.DashboardIcon} className="mr-15" width={20} />
              )}
              Dashboard
            </li>
            <li
              className={title === "users" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("users");
              }}
            >
              {location.pathname.includes("users") ? (
                <img
                  src={Images.UsersSelectedIcon}
                  width={24}
                  style={{ marginRight: "13px" }}
                />
              ) : (
                <img src={Images.UsersIcon} className="mr-15" />
              )}
              User Management
            </li>
            <li
              className={title === "song-file" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("song-file");
              }}
            >
              {location.pathname.includes("song") ? (
                <img
                  src={Images.SongSelectedIcon}
                  width={15}
                  style={{ marginRight: "22px" }}
                />
              ) : (
                <img
                  src={Images.SongIcon}
                  width={16}
                  style={{ marginRight: "20px" }}
                />
              )}
              Media File
            </li>
            <li
              className={title === "media-feedback" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("media-feedback");
              }}
            >
              {location.pathname.includes("media") ? (
                <img
                  src={Images.ReportSelectedIcon}
                  width={20}
                  style={{ marginRight: "18px" }}
                />
              ) : (
                <img
                  src={Images.ReportIcon}
                  style={{ marginRight: "18px" }}
                  width={20}
                />
              )}
              Feedback
            </li>
            <li
              className={title === "change-password" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("change-password");
              }}
            >
              {location.pathname.includes("change-password") ? (
                <img
                  src={Images.SecuritySelectedIcon}
                  width={15}
                  style={{ marginRight: "20px" }}
                />
              ) : (
                <img
                  src={Images.SecurityIcon}
                  style={{ marginRight: "20px" }}
                  width={18}
                />
              )}
              Change Password
            </li>
            <li
              className={title === "promo-codes" && "selected"}
              onClick={() => {
                setVisible(false);
                navigate("promo-codes");
              }}
            >
              {location.pathname.includes("promo-codes") ? (
                <img
                  src={Images.PromoCodeSelectedIcon}
                  width={30}
                  style={{ marginRight: "10px" }}
                />
              ) : (
                <img
                  src={Images.PromoCodeIcon}
                  style={{ marginRight: "16px" }}
                  width={22}
                />
              )}
              Promo Code
            </li>
            <li className={"sidebar-heading-mobile"} onClick={handleLogout}>
              <FiLogOut style={styles.logoutIconDiv} />
              Logout
            </li>
          </ul>
        </div>
      </Drawer>
    </>
  );

  return (
    <div
      className="nav-header-container"
      style={{ justifyContent: isTablet ? "flex-end" : "space-between" }}
    >
      {/* <div>
        {expandible &&
          React.createElement(collapsed ? MenuOutlined : MoreOutlined, {
            className: "trigger bg-purple",
            onClick: toggle,
          })}
        <h3 className="dashboard-title">
          {title !== "" ? title : "Dashboard"}
        </h3>
      </div> */}
      {!isTablet && <Hamburger placement={"left"} />}
      <div className="site-layout-background-right">
        {/* <div className={"headerIcons"}>
          <Dropdown
            trigger={"click"}
            // overlay={<NotificationDropDown />}
            style={{ height: "65px" }}
          >
            <div>
              <img src={notificationsBell} atl="disapper" />
              </div>
          </Dropdown>
        </div> */}
        <div className="profile">
          {/* <Dropdown
            overlay={<ProfileDropDown />}
            style={{ top: "65px !important" }}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            > */}
          <img
            src={Images.DefaultAvatar}
            alt="disappear"
            style={{ width: "40px", height: "auto" }}
          />
          {isMobile && <span>{authName ? authName : LSauthName}</span>}
        </div>
      </div>
    </div>
  );
};
export default NavHeader;
