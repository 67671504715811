import { Row } from "antd";
import { useMedia } from "use-media";
import React from "react";

import { Images } from "../../config";
import styles from "./style";

function LoginBox({ children, size = "large" }) {
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  return (
    <div style={styles.outerContainer}>
      <Row
        gutter={isMobile ? [50, 25] : [20, 0]}
        justify="center"
        style={{
          ...styles.container,
          ...{
            paddingTop:
              size === "small"
                ? isMobile
                  ? "100px"
                  : isSmallMobile
                  ? "85px"
                  : "70px"
                : "80px",
            width: isMobile ? "450px" : isSmallMobile ? "360px" : "300px",
          },
        }}
      >
        <img
          src={Images.Logo}
          style={{
            ...styles.appIcon,
            ...{
              top:
                size === "small"
                  ? isMobile
                    ? "-19%"
                    : isSmallMobile
                    ? "-17.5%"
                    : "-16.5%"
                  : isMobile
                  ? "-15%"
                  : isSmallMobile
                  ? "-15%"
                  : "-11%",
              left: isMobile ? "33%" : isSmallMobile ? "30%" : "30%",
            },
          }}
          alt=""
          width={isMobile ? 150 : isSmallMobile ? 130 : 110}
        />
        {children}
      </Row>
    </div>
  );
}

export default LoginBox;
