import { Bar, BarChart, Brush, Cell, Tooltip, XAxis, YAxis } from "recharts";
import propTypes from "prop-types";
import React from "react";

// For Testing Chart: Pass data array into data as a prop, put value as dataKey, put name as nameKey

class BarChartWrapper extends React.PureComponent {
  render() {
    var obj, obj2, numId;
    if (this.props.data[0]) obj = Object.keys(this.props.data[0]);

    return (
      <BarChart
        style={{ margin: "auto" }}
        margin={this.props.margin}
        width={this.props.width}
        height={this.props.height}
        data={this.props.data}
        layout="horizontal"
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          type="category"
          dataKey={this.props.isLocation ? "name" : "title"}
        />
        <YAxis
          type="number"
          dataKey={this.props.isLocation ? "value" : "play_count"}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            return (
              <div
                className="custom-tooltip"
                style={{
                  borderRadius: 5,
                  border: "none",
                  padding: 20,
                  paddingBottom: 10,
                  backgroundColor: "white",
                  color: "black",
                  minWidth: "10rem",
                  maxWidth: "30rem",
                }}
              >
                {this.props.isLocation ? (
                  <>
                    <p className="label mr-b">
                      Location:&nbsp;
                      <span style={{ wordBreak: "normal" }}>
                        {payload[0]?.payload?.name}
                      </span>
                    </p>
                    <p className="label mr-b">
                      Value:&nbsp;
                      <span style={{ wordBreak: "normal" }}>
                        {payload[0]?.payload?.value}
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="label mr-b">
                      Title:&nbsp;
                      <span style={{ wordBreak: "normal" }}>
                        {payload[0]?.payload?.title}
                      </span>
                    </p>
                    <p className="label mr-b">
                      Media type:&nbsp;
                      <span style={{ wordBreak: "normal" }}>
                        {payload[0]?.payload?.type}
                      </span>
                    </p>
                    <p className="label mr-b">
                      Play count:&nbsp;
                      <span style={{ wordBreak: "normal" }}>
                        {payload[0]?.payload?.play_count}
                      </span>
                    </p>
                    <p className="label mr-b">
                      Download count:&nbsp;
                      <span style={{ wordBreak: "normal" }}>
                        {payload[0]?.payload?.purchase_count}
                      </span>
                    </p>
                  </>
                )}
              </div>
            );
          }}
        />
        {/* <Legend /> */}
        <Bar dataKey={this.props.isLocation ? obj[1] : obj[2]}>
          {this.props.isLocation
            ? this.props.data?.map((item, index) => (
                <Cell key={`cell-${index}`} fill={"#7836FF"} />
              ))
            : this.props.data?.map((item, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={item.type === "video" ? "#7836FF" : "#F0AC02"}
                />
              ))}
        </Bar>
        {this.props.data.length < 40 ? (
          <Brush dataKey="name" height={30} stroke="#82b1ee" />
        ) : (
          <Brush
            startIndex={0}
            endIndex={40}
            dataKey="name"
            height={30}
            stroke="#82b1ee"
          />
        )}
      </BarChart>
    );
  }
}

BarChartWrapper.defaultProps = {
  width: 400,
  height: 300,
  margin: { top: 0, right: 50, left: 50, bottom: 0 },
  isTooltip: true,
  isLegend: false,
  size: null,
  label: true,
  legendType: "circle",
};

BarChartWrapper.propTypes = {
  data: propTypes.array.isRequired,
  // pieColor: propTypes.array.isRequired
};

export default BarChartWrapper;
