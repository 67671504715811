import {
  ADD_PROMO_CODE,
  ADD_PROMO_CODE_FAILURE,
  ADD_PROMO_CODE_SUCCESS,
  CHANGE_PROMO_CODE_STATUS,
  CHANGE_PROMO_CODE_STATUS_FAILURE,
  CHANGE_PROMO_CODE_STATUS_SUCCESS,
  DELETE_PROMO_CODE,
  DELETE_PROMO_CODE_FAILURE,
  DELETE_PROMO_CODE_SUCCESS,
  GET_PROMO_CODES,
  GET_PROMO_CODES_FAILURE,
  GET_PROMO_CODES_SUCCESS,
} from "../constants";

export default class PromoCodeAction {
  static getPromoCodes(payload, cb) {
    return {
      type: GET_PROMO_CODES,
      payload,
      cb,
    };
  }
  static getPromoCodesSuccess(payload) {
    return {
      type: GET_PROMO_CODES_SUCCESS,
      payload,
    };
  }
  static getPromoCodesFailure() {
    return {
      type: GET_PROMO_CODES_FAILURE,
    };
  }

  static changePromoCodeStatus(id, cb) {
    return {
      type: CHANGE_PROMO_CODE_STATUS,
      id,
      cb,
    };
  }
  static changePromoCodeStatusSuccess(payload) {
    return {
      type: CHANGE_PROMO_CODE_STATUS_SUCCESS,
      payload,
    };
  }
  static changePromoCodeStatusFailure() {
    return {
      type: CHANGE_PROMO_CODE_STATUS_FAILURE,
    };
  }
  static deletePromoCode(id, cb) {
    return {
      type: DELETE_PROMO_CODE,
      id,
      cb,
    };
  }
  static deletePromoCodeSuccess(payload) {
    return {
      type: DELETE_PROMO_CODE_SUCCESS,
      payload,
    };
  }
  static deletePromoCodeFailure() {
    return {
      type: DELETE_PROMO_CODE_FAILURE,
    };
  }

  static addPromoCode(payload, cb) {
    return {
      type: ADD_PROMO_CODE,
      payload,
      cb,
    };
  }
  static addPromoCodeSuccess(payload) {
    return {
      type: ADD_PROMO_CODE_SUCCESS,
      payload,
    };
  }
  static addPromoCodeFailure() {
    return {
      type: ADD_PROMO_CODE_FAILURE,
    };
  }
}
