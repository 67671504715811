import { Link, useLocation, useNavigate } from "react-router-dom";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as Icon from "react-icons/all";
import React, { useEffect } from "react";

import { AuthAction } from "../../store/actions";
import { Images } from "../../config";
import styles from "./style";

import "./sidebar.css";

const SideBar = ({ collapsed }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector((state) => state.media.addSongLoader);

  const handleLogout = () => {
    dispatch(
      AuthAction.Logout(() => {
        navigate("/login");
      })
    );
  };

  useEffect(() => {
    if (!isLoading) {
      const menu = document.querySelector(".ant-menu").children;
      for (let i = 0; i < menu.length; i++) {
        if (location.pathname.includes(menu[i].id)) {
          menu[i].classList.add("ant-menu-item-selected");
        } else {
          menu[i].classList.remove("ant-menu-item-selected");
        }
      }
    }
  }, [location]);

  const renderMainNavOptions = () => {
    return (
      <>
        <Menu.Item
          className="menu-item"
          id="dashboard"
          key="1"
          icon={
            location.pathname.includes("dashboard") ? (
              <span className="icon-div" style={{ marginRight: "10px" }}>
                <img src={Images.DashboardSelectedIcon} width={16} />
              </span>
            ) : (
              <img src={Images.DashboardIcon} className="mr-15" width={20} />
            )
          }
        >
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item
          className="menu-item"
          id="users"
          key="2"
          icon={
            location.pathname.includes("users") ? (
              <span className="icon-div" style={{ marginRight: "7px" }}>
                <img src={Images.UsersSelectedIcon} width={18} />
              </span>
            ) : (
              <img src={Images.UsersIcon} className="mr-15" />
            )
          }
        >
          <Link to="/users">User Management</Link>
        </Menu.Item>
        <Menu.Item
          className="menu-item"
          key="3"
          id="song"
          icon={
            location.pathname.includes("song") ? (
              <span className="icon-div" style={{ marginRight: "16px" }}>
                <img src={Images.SongSelectedIcon} width={12} />
              </span>
            ) : (
              <img
                src={Images.SongIcon}
                width={16}
                style={{ marginRight: "20px" }}
              />
            )
          }
        >
          <Link to="/song-file">Media File</Link>
        </Menu.Item>
        <Menu.Item
          className="menu-item"
          id="media"
          key="4"
          icon={
            location.pathname.includes("media") ? (
              <span className="icon-div" style={{ marginRight: "10px" }}>
                <img src={Images.ReportSelectedIcon} width={16} />
              </span>
            ) : (
              <img
                src={Images.ReportIcon}
                style={{ marginRight: "18px" }}
                width={20}
              />
            )
          }
        >
          <Link to="/media-feedback">Feedback</Link>
        </Menu.Item>
        <Menu.Item
          className="menu-item"
          id="change-password"
          key="5"
          icon={
            location.pathname.includes("change-password") ? (
              <span className="icon-div" style={{ marginRight: "12px" }}>
                <img src={Images.SecuritySelectedIcon} width={14} />
              </span>
            ) : (
              <img
                src={Images.SecurityIcon}
                style={{ marginRight: "20px" }}
                width={18}
              />
            )
          }
        >
          <Link to="/change-password">Change Password</Link>
        </Menu.Item>
        <Menu.Item
          className="menu-item"
          id="promo-codes"
          key="6"
          icon={
            location.pathname.includes("promo-codes") ? (
              <span
                className="icon-div"
                style={{
                  marginRight: "10px",
                  padding: "4px",
                }}
              >
                <img src={Images.PromoCodeSelectedIcon} width={26} />
              </span>
            ) : (
              <img
                src={Images.PromoCodeIcon}
                style={{ marginRight: "16px" }}
                width={22}
              />
            )
          }
        >
          <Link to="/promo-codes">Promo Code</Link>
        </Menu.Item>
      </>
    );
  };

  return (
    <div className={`sidebar ${collapsed && "sidebar-closed"}`}>
      <div className="logo">
        <img src={Images.LogoWithText} />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        className="sidebar-menu dashboard-menu"
      >
        {renderMainNavOptions()}
        <Menu.Item
          className="menu-item sidebar-heading"
          key="6"
          icon={<Icon.FiLogOut style={styles.logoutIconDiv} />}
        >
          <Link to="" onClick={handleLogout}>
            Logout
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};
export default SideBar;
