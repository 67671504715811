import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { PromoCodeAction } from "../actions";

export default class PromoCodeMiddleware {
  static *GetPromoCodes({ payload, cb }) {
    try {
      const { limit = 10, offset = 0 } = payload;
      let res = yield ApiCaller.Get(
        `/promo/list?limit=${limit}&offset=${offset}`
      );
      if (res.status == 200) {
        yield put(PromoCodeAction.getPromoCodesSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(PromoCodeAction.getPromoCodesFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(PromoCodeAction.getPromoCodesFailure());
      //   Utils.showSnackBar({
      //     message: "Reset password failed",
      //     severity: "error",
      //   });
    }
  }

  static *ChangePromoCodeStatus({ id, cb }) {
    try {
      let res = yield ApiCaller.Put(`/promo/status/toggle?promo_code_id=${id}`);
      if (res.status == 200) {
        yield put(PromoCodeAction.changePromoCodeStatusSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(
          PromoCodeAction.changePromoCodeStatusFailure(res.data.message)
        );
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(yield put(PromoCodeAction.changePromoCodeStatusFailure()));
    }
  }

  static *DeletePromoCode({ id, cb }) {
    try {
      let res = yield ApiCaller.Delete(`/promo/delete?promo_code_id=${id}`);
      if (res.status == 200) {
        yield put(PromoCodeAction.deletePromoCodeSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(PromoCodeAction.deletePromoCodeFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(PromoCodeAction.deletePromoCodeFailure());
    }
  }

  static *AddPromoCode({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post(`/promo/create`, payload);
      if (res.status == 200) {
        yield put(PromoCodeAction.addPromoCodeSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb(res);
        }
      } else {
        yield put(PromoCodeAction.addPromoCodeFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(PromoCodeAction.addPromoCodeFailure());
    }
  }
}
