import Basic from "./Basic.jsx";
import Floating from "./Floating.jsx";
import Upload from "./Upload.jsx";

export default {
  Basic,
  Upload,
  Floating,
  
};
