import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
} from "../constants";

export default class AuthAction {
  static SignIn(payload, cb) {
    return {
      type: SIGN_IN,
      payload,
      cb,
    };
  }
  static SignInSuccess(payload) {
    return {
      type: SIGN_IN_SUCCESS,
      payload,
    };
  }
  static SignInFailure() {
    return {
      type: SIGN_IN_FAILURE,
    };
  }

  static Logout() {
    return {
      type: LOGOUT,
    };
  }
  static LogoutSuccess() {
    return {
      type: LOGOUT_SUCCESS,
    };
  }
  static LogoutFailure() {
    return {
      type: LOGOUT_FAILURE,
    };
  }

  static ForgotPassword(payload, cb) {
    return {
      type: FORGOT_PASSWORD,
      payload,
      cb,
    };
  }
  static ForgotPasswordSuccess(payload) {
    return {
      type: FORGOT_PASSWORD_SUCCESS,
      payload,
    };
  }
  static ForgotPasswordFailure() {
    return {
      type: FORGOT_PASSWORD_FAILURE,
    };
  }

  static verifyCode(payload, cb) {
    return {
      type: VERIFY_OTP,
      payload,
      cb,
    };
  }
  static verifyCodeSuccess(payload) {
    return {
      type: VERIFY_OTP_SUCCESS,
      payload,
    };
  }
  static verifyCodeFailure(payload) {
    return {
      type: VERIFY_OTP_FAILURE,
      payload,
    };
  }

  static resetPassword(payload, cb) {
    return {
      type: RESET_PASSWORD,
      payload,
      cb,
    };
  }
  static resetPasswordSuccess(payload) {
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload,
    };
  }
  static resetPasswordFailure(payload) {
    return {
      type: RESET_PASSWORD_FAILURE,
      payload,
    };
  }

  static changePassword(payload, cb) {
    return {
      type: CHANGE_PASSWORD,
      payload,
      cb,
    };
  }
  static changePasswordSuccess(payload) {
    return {
      type: CHANGE_PASSWORD_SUCCESS,
      payload,
    };
  }
  static changePasswordFailure(payload) {
    return {
      type: CHANGE_PASSWORD_FAILURE,
      payload,
    };
  }

  static LoaderTrue() {
    return {
      type: LOADER_TRUE,
    };
  }
  static LoaderFalse() {
    return {
      type: LOADER_FALSE,
    };
  }
}
