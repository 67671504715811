import {
  ADD_MEDIA,
  ADD_MEDIA_FAILURE,
  ADD_MEDIA_SUCCESS,
  DELETE_MEDIA,
  DELETE_MEDIA_FAILURE,
  DELETE_MEDIA_LYRICS,
  DELETE_MEDIA_LYRICS_FAILURE,
  DELETE_MEDIA_LYRICS_SUCCESS,
  DELETE_MEDIA_SUCCESS,
  EDIT_MEDIA,
  EDIT_MEDIA_FAILURE,
  EDIT_MEDIA_SUCCESS,
  GET_MEDIA_LISTING,
  GET_MEDIA_LISTING_FAILURE,
  GET_MEDIA_LISTING_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  addSongLoader: false,
  mediaList: [],
  count: 0,
  media: {},
  error: null,
};

export default function MediaReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MEDIA_LISTING:
    case DELETE_MEDIA:
    case DELETE_MEDIA_LYRICS:
      state = {
        mediaList: [],
        count: 0,
        media: {},
        error: null,
        isLoading: true,
        addSongLoader: false,
      };
      break;
    case ADD_MEDIA:
    case EDIT_MEDIA:
      state = {
        ...state,
        error: null,
        addSongLoader: true,
      };
      break;
    case GET_MEDIA_LISTING_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        count: action.payload.total,
        mediaList: action.payload.records,
      };
      break;
    case ADD_MEDIA_SUCCESS:
    case EDIT_MEDIA_SUCCESS:
      state = {
        ...state,
        addSongLoader: false,
        error: null,
      };
      break;
    case DELETE_MEDIA_SUCCESS:
    case DELETE_MEDIA_LYRICS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        error: null,
      };
      break;
    case ADD_MEDIA_FAILURE:
    case EDIT_MEDIA_FAILURE:
      state = {
        ...state,
        addSongLoader: false,
        error: action.payload,
      };
      break;
    case GET_MEDIA_LISTING_FAILURE:
    case DELETE_MEDIA_FAILURE:
    case DELETE_MEDIA_LYRICS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
