import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { MediaAction } from "../actions";

export default class MediaMiddleware {
  static *GetMediaListing({ payload, cb }) {
    try {
      const {
        limit = 10,
        offset = 0,
        sort_by = "",
        order = "",
        media_type = "",
        media_category = "",
        title = "",
      } = payload;
      let res = yield ApiCaller.Get(
        `/media/list?limit=${limit}&offset=${offset}&sort_by=${sort_by}&order=${order}&media_type=${media_type}&media_category=${media_category}&keyword=${title}`
      );
      if (res.status == 200) {
        yield put(MediaAction.getMediaListingSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(MediaAction.getMediaListingFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(yield put(MediaAction.getMediaListingFailure()));
      //   Utils.showSnackBar({
      //     message: "Reset password failed",
      //     severity: "error",
      //   });
    }
  }

  static *DeleteMedia({ id, cb }) {
    try {
      let res = yield ApiCaller.Delete(`/media/delete?media_id=${id}`);
      if (res.status == 200) {
        yield put(MediaAction.deleteMediaSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(MediaAction.deleteMediaFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(yield put(MediaAction.deleteMediaFailure()));
    }
  }

  static *AddMedia({ data, cb }) {
    try {
      let res = yield ApiCaller.Post(`/media/upload`, data);
      if (res.status == 200) {
        yield put(MediaAction.addMediaSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb(res);
        }
      } else {
        yield put(MediaAction.addMediaFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(yield put(MediaAction.addMediaFailure()));
    }
  }

  static *EditMedia({ data, cb }) {
    try {
      let res = yield ApiCaller.Post(`/media/update`, data);
      if (res.status == 201) {
        yield put(MediaAction.editMediaSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb(res);
        }
      } else {
        yield put(MediaAction.editMediaFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(yield put(MediaAction.editMediaFailure()));
    }
  }

  static *DeleteMediaLyrics({ id, cb }) {
    try {
      let res = yield ApiCaller.Delete(`/media/lyrics/delete?media_id=${id}`);
      if (res.status == 200) {
        yield put(MediaAction.deleteMediaLyricsSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(MediaAction.deleteMediaLyricsFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(yield put(MediaAction.deleteMediaLyricsFailure()));
    }
  }
}
