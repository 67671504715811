import moment from "moment";

import {
  GET_AUDIO_PURCHASES,
  GET_AUDIO_PURCHASES_FAILURE,
  GET_AUDIO_PURCHASES_SUCCESS,
  GET_MEDIA_COUNT,
  GET_MEDIA_COUNT_FAILURE,
  GET_MEDIA_COUNT_SUCCESS,
  GET_MEDIA_PLAY_COUNT_STATS,
  GET_MEDIA_PLAY_COUNT_STATS_FAILURE,
  GET_MEDIA_PLAY_COUNT_STATS_SUCCESS,
  GET_MEDIA_TYPE_STATS,
  GET_MEDIA_TYPE_STATS_FAILURE,
  GET_MEDIA_TYPE_STATS_SUCCESS,
  GET_STREAMS_COUNT,
  GET_STREAMS_COUNT_FAILURE,
  GET_STREAMS_COUNT_SUCCESS,
  GET_SUBSCRIPTIONS_COUNT,
  GET_SUBSCRIPTIONS_COUNT_FAILURE,
  GET_SUBSCRIPTIONS_COUNT_SUCCESS,
  GET_USERS_COUNT,
  GET_USERS_COUNT_FAILURE,
  GET_USERS_COUNT_SUCCESS,
  GET_USER_LOCATION_STATS,
  GET_USER_LOCATION_STATS_FAILURE,
  GET_USER_LOCATION_STATS_SUCCESS,
  GET_USER_SIGNUP_STATS,
  GET_USER_SIGNUP_STATS_FAILURE,
  GET_USER_SIGNUP_STATS_SUCCESS,
  GET_VIDEO_PURCHASES,
  GET_VIDEO_PURCHASES_FAILURE,
  GET_VIDEO_PURCHASES_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  userCount: 0,
  subscriptionCount: 0,
  mediaCount: 0,
  streamCount: 0,
  audioPurchases: 0,
  videoPurchases: 0,
  userSignupStats: [],
  mediaTypeStats: [],
  mediaPlayCountStats: [],
  userLocationStats: [],
  error: null,
};

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_COUNT:
    case GET_SUBSCRIPTIONS_COUNT:
    case GET_MEDIA_COUNT:
    case GET_STREAMS_COUNT:
    case GET_AUDIO_PURCHASES:
    case GET_VIDEO_PURCHASES:
    case GET_USER_SIGNUP_STATS:
    case GET_MEDIA_PLAY_COUNT_STATS:
    case GET_MEDIA_TYPE_STATS:
    case GET_USER_LOCATION_STATS:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case GET_USERS_COUNT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        userCount: action.payload,
      };
      break;
    case GET_SUBSCRIPTIONS_COUNT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        subscriptionCount: action.payload,
      };
      break;
    case GET_MEDIA_COUNT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        mediaCount: action.payload,
      };
      break;
    case GET_STREAMS_COUNT_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        streamCount: action.payload,
      };
      break;
    case GET_AUDIO_PURCHASES_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        audioPurchases: action.payload,
      };
      break;
    case GET_VIDEO_PURCHASES_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        videoPurchases: action.payload,
      };
      break;
    case GET_USER_SIGNUP_STATS_SUCCESS:
      const dataGraph = action.payload.map((item) => {
        return {
          name: `${moment(item?.name).format("DD-MMM-YY")}`,
          details: item,
          value: item.value,
        };
      });
      state = {
        ...state,
        isLoading: false,
        userSignupStats: dataGraph,
      };
      break;
    case GET_MEDIA_TYPE_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        mediaTypeStats: action.payload,
      };
      break;
    case GET_MEDIA_PLAY_COUNT_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        mediaPlayCountStats: action.payload,
      };
      break;
    case GET_USER_LOCATION_STATS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        userLocationStats: action.payload,
      };
      break;
    case GET_USERS_COUNT_FAILURE:
    case GET_SUBSCRIPTIONS_COUNT_FAILURE:
    case GET_MEDIA_COUNT_FAILURE:
    case GET_STREAMS_COUNT_FAILURE:
    case GET_AUDIO_PURCHASES_FAILURE:
    case GET_VIDEO_PURCHASES_FAILURE:
    case GET_USER_SIGNUP_STATS_FAILURE:
    case GET_MEDIA_TYPE_STATS_FAILURE:
    case GET_MEDIA_PLAY_COUNT_STATS_FAILURE:
    case GET_USER_LOCATION_STATS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
