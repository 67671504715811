import { Col, Row } from "antd";
import React, { useState } from "react";

import { Button, Text, TextField } from "../../components";
import { Colors, Fonts, Utils } from "../../config";

import {
  MontserratBold,
  MontserratLight,
  PoppinsSemiBold,
} from "../../config/fonts";

import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import { AuthAction } from "../../store/actions";
import styles from "./index.module.css";

function ChangePassword() {
  const dispatch = useDispatch();
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const isLoading = useSelector((state) => state.auth.changeLoader);
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [error, setError] = useState({
    currentPasswordError: "",
    newPasswordError: "",
    confirmPasswordError: "",
  });

  const handleUpdatePassword = () => {
    const { currentPassword, newPassword, confirmPassword } = state;
    const { currentPasswordError, newPasswordError, confirmPasswordError } =
      error;
    if (!currentPassword) {
      setError({
        ...state,
        currentPasswordError: "Please enter current password",
      });
    } else if (!Utils.passwordRegex.test(currentPassword)) {
      setError({
        ...state,
        currentPasswordError: "Invalid password",
      });
    } else if (!newPassword) {
      setError({
        ...state,
        newPasswordError: "Please enter new password",
      });
    } else if (!Utils.passwordRegex.test(newPassword)) {
      setError({
        ...state,
        newPasswordError:
          "Password should be atleast 8 characters long with 1 lowercase. 1 uppercase and 1 special character",
      });
    } else if (!confirmPassword) {
      setError({
        ...state,
        confirmPasswordError: "Please confirm password",
      });
    } else if (!Utils.passwordRegex.test(confirmPassword)) {
      setError({
        ...state,
        confirmPasswordError:
          "Password should be atleast 8 characters long with 1 lowercase. 1 uppercase and 1 special character",
      });
    } else if (newPassword !== confirmPassword) {
      setError({
        ...state,
        confirmPasswordError: "Passwords did not match",
      });
    } else {
      setError({
        currentPasswordError: "",
        newPasswordError: "",
        confirmPasswordError: "",
      });
      const payload = {
        current_password: state.currentPassword,
        new_password: state.newPassword,
      };
      dispatch(
        AuthAction.changePassword(payload, () => {
          setState({
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
        })
      );
    }
  };

  return (
    <div className="wrapper">
      <Row className="mb-20 mx-15">
        <Col className="flex-column">
          <Text.Basic
            text="Change Password"
            color={Colors.Black}
            fontSize={isMobile ? 25 : isSmallMobile ? 20 : 18}
            fontWeight={600}
            fontFamily={Fonts[MontserratBold]}
            textAlign="left"
          />
          <Text.Basic
            text="You can update your new password"
            color={Colors.LightGray}
            fontSize={isMobile ? 16 : isSmallMobile ? 14 : 12}
            fontWeight={300}
            fontFamily={Fonts[MontserratLight]}
            textAlign="left"
          />
        </Col>
      </Row>
      <Row justify={"center"}>
        <Col xxl={7} lg={9} md={12} sm={14} xs={22} className={styles.card}>
          <Row className="mb-20">
            <Col span={24}>
              <TextField.Basic
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                type={"password"}
                value={state.currentPassword}
                placeholder={"Password"}
                color={Colors.inputText}
                onChange={(e) =>
                  setState({
                    ...state,
                    currentPassword: e.target.value,
                  })
                }
                label="Current Password"
                password
                errorText={
                  error.currentPasswordError && error.currentPasswordError
                }
              />
            </Col>
          </Row>
          <Row className="mb-20">
            <Col span={24}>
              <TextField.Basic
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                type={"password"}
                value={state.newPassword}
                placeholder={"Password"}
                color={Colors.inputText}
                onChange={(e) =>
                  setState({
                    ...state,
                    newPassword: e.target.value,
                  })
                }
                label="New Password"
                password
                errorText={error.newPasswordError && error.newPasswordError}
              />
            </Col>
          </Row>
          <Row className="mb-20">
            <Col span={24}>
              <TextField.Basic
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                type={"password"}
                value={state.confirmPassword}
                placeholder={"Password"}
                color={Colors.inputText}
                onChange={(e) =>
                  setState({
                    ...state,
                    confirmPassword: e.target.value,
                  })
                }
                label="Confirm Password"
                password
                errorText={
                  error.confirmPasswordError && error.confirmPasswordError
                }
              />
            </Col>
          </Row>
          <Row justify={"center"}>
            <Col span={24}>
              <Button.Basic
                text="Update"
                size={isMobile && "large"}
                onClick={handleUpdatePassword}
                loading={isLoading}
                disabled={isLoading}
                buttonStyle={{
                  width: "100%",
                  height: "50px",
                  backgroundColor: "#7836FF",
                  color: "white",
                  fontSize: "20px",
                  fontFamily: Fonts[PoppinsSemiBold],
                  borderRadius: "8px",
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ChangePassword;
