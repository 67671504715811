import { Colors } from "../../config";

const styles = {
  outerContainer: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: Colors.LightBlue,
    borderRadius: "25px",
    paddingBottom: "30px",
    width: "450px",
    position: "relative",
  },
  appIcon: {
    position: "absolute",
    left: "33%",
  },
  logoutIconDiv: {
    color: "#fff",
    fontSize: "30px",
    transform: "scaleX(-1)",
    marginRight: "10px",
    backgroundColor: Colors.Red,
    padding: "5px",
    borderRadius: "4px",
  },
};

export default styles;
