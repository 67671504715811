import { Col, Popconfirm, Row, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useMedia } from "use-media";
import * as Icon from "react-icons/all";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import { Button, Modal, Select, Text, TextField } from "../../components";
import { Colors, Fonts, Images, Utils } from "../../config";
import { MediaAction } from "../../store/actions";
import { MontserratMedium, MontserratRegular } from "../../config/fonts";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";

import styles from "./index.module.css";

const { Dragger } = Upload;

const audioFormats = ["mp3", "m4a", "flac"];
const videoFormats = ["mp4", "mov"];

function AddSong() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isTablet = useMedia({ minWidth: "770px" });
  const isMobile = useMedia({ minWidth: "450px" });
  const imageFile = useRef(null);
  const SRTFile = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  const isLoading = useSelector((state) => state.media.addSongLoader);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isLoading);
  const [isAudioTab, setIsAudioTab] = useState(
    location?.state?.song
      ? location?.state?.song?.type === "audio"
        ? true
        : false
      : true
  );
  const [fileList, setFileList] = useState([]);
  const [imageThubnail, setImageThumbnail] = useState(
    location?.state?.song
      ? `https://nileurdata.s3.eu-west-2.amazonaws.com/${location?.state?.song?.thumbnail}`
      : null
  );
  const duration = location?.state?.song?.duration;
  const [state, setState] = useState({
    title: location?.state?.song ? location?.state?.song?.title : "",
    artistName:
      location?.state?.song && location?.state?.song.artist !== "null"
        ? location?.state?.song?.artist
        : "",
    category: location?.state?.song ? location?.state?.song?.category : "",
    description: location?.state?.song
      ? location?.state?.song?.description
      : "",
    fileType: location?.state?.song ? location?.state?.song?.type : "audio",
    downloadable: location?.state?.song
      ? location?.state?.song?.downloadable === 1
        ? true
        : false
      : false,
    thumbnail: null,
    lyrics: null,
    file: null,
  });
  const [error, setError] = useState({
    titleError: "",
    artistNameError: "",
    categoryError: "",
    descriptionError: "",
    fileError: null,
    lyricsError: "",
  });

  const handleSubmitMedia = () => {
    const {
      title,
      artistName,
      category,
      description,
      fileType,
      file,
      thumbnail,
      downloadable,
      lyrics,
    } = state;
    const {
      titleError,
      artistNameError,
      categoryError,
      descriptionError,
      fileTypeError,
      fileError,
      lyricsError,
    } = error;
    if (!title) {
      setError({
        ...error,
        titleError: "Title cannot be left empty",
      });
    } else if (title.length < 5 || title.length > 40) {
      setError({
        ...error,
        titleError: "Title must be minimum 5 and maximum 40 characters",
      });
    } else if (
      artistName &&
      (artistName.length < 5 || artistName.length > 20)
    ) {
      setError({
        ...error,
        artistNameError:
          "Artist name must be minimum 5 and maximum 20 characters",
      });
    } else if (!description) {
      setError({
        ...error,
        descriptionError: "Description cannot be left empty",
      });
    } else if (description.length < 5 || description.length > 5000) {
      setError({
        ...error,
        descriptionError:
          "Description must be minimum 5 and maximum 5000 characters",
      });
    } else if (!location?.state?.song && file === null) {
      setError({
        ...error,
        fileError: "File cannot be left empty",
      });
    } else {
      setError({
        ...error,
        fileError: "",
      });
      if (location?.state?.song) {
        let data = new FormData();
        data.append("title", title);
        data.append("artist", artistName);
        data.append("description", description);
        data.append(
          "media_category",
          category === "Divine Music"
            ? "Divine-Music"
            : category === "Break and Groove"
            ? "Break-and-Groove"
            : category
        );
        data.append("media_id", String(location?.state?.song?.id));
        data.append("downloadable", downloadable ? "1" : "0");
        if (thumbnail) {
          data.append("thumbnail", thumbnail);
        }
        if (lyrics) {
          data.append("lyrics", lyrics);
        }
        dispatch(
          MediaAction.editMedia(data, (res) => {
            navigate("/song-file");
          })
        );
      } else {
        let data = new FormData();
        data.append("title", title);
        data.append("artist", artistName);
        data.append("description", description);
        data.append("media_type", fileType);
        data.append("media_category", category);
        data.append("downloadable", downloadable ? "1" : "0");
        if (lyrics) {
          data.append("lyrics", lyrics);
        }
        if (thumbnail) {
          data.append("thumbnail", thumbnail);
        }
        if (isAudioTab) {
          data.append("audio", file);
        } else {
          data.append("video", file);
        }
        dispatch(
          MediaAction.addMedia(data, () => {
            navigate("/song-file");
          })
        );
      }
    }
  };

  const fileUpload = (tab, file) => {
    const fileFormatSplitLength = file?.name?.split(".");
    const fileFormat = fileFormatSplitLength[fileFormatSplitLength.length - 1];
    if (tab) {
      if (!audioFormats.includes(fileFormat.toLowerCase())) {
        setError({
          ...error,
          fileError: "File format not supported",
        });
        Utils.showSnackBar({
          message: "Media file format not supported",
          severity: "error",
        });
      } else {
        setState({
          ...state,
          file: file.originFileObj,
        });
        setError({
          ...error,
          fileError: "",
        });
        Utils.showSnackBar({ message: "Media file uploaded successfully" });
      }
    } else if (!tab) {
      if (!videoFormats.includes(fileFormat.toLowerCase())) {
        setError({
          ...error,
          fileError: "File format not supported",
        });
        Utils.showSnackBar({
          message: "Media file format not supported",
          severity: "error",
        });
      } else {
        setState({
          ...state,
          file: file.originFileObj,
        });
        setError({
          ...error,
          fileError: "",
        });
        Utils.showSnackBar({ message: "Media file uploaded successfully" });
      }
    }
    setFileList([]);
  };

  const handleChangeFileType = (flag) => {
    if (!location?.state?.song) {
      setIsAudioTab(flag);
      setError({
        ...error,
        titleError: "",
        artistNameError: "",
        categoryError: "",
        descriptionError: "",
        lyricsError: "",
        fileError: null,
      });
      setState({
        title: "",
        artistName: "",
        category: "",
        description: "",
        fileType: flag ? "audio" : "video",
        thumbnail: null,
        lyrics: null,
        downloadable: false,
        file: null,
      });
      setImageThumbnail(null);
    }
  };
  const handleFileChange = (info) => {
    fileUpload(isAudioTab, info.file);
  };

  const handleFileDrop = (info) => {
    fileUpload(isAudioTab, info.file);
  };

  const handleImageFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setIsEdit(true);
    setState({
      ...state,
      thumbnail: selectedFile,
    });
    Utils.showSnackBar({ message: "Thumbnail uploaded successfully" });
  };

  const handleSRTFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const fileExt = e.target.files[0]?.name.split(".");
    if (fileExt[fileExt.length - 1] === "srt") {
      setIsEdit(true);
      setState({
        ...state,
        lyrics: selectedFile,
      });
      setError({
        ...error,
        lyricsError: "",
      });
      Utils.showSnackBar({ message: "Lyrics file uploaded successfully" });
    } else {
      Utils.showSnackBar({
        message: "Lyrics file format not supported",
        severity: "error",
      });
    }
  };

  const handleCancelSubmit = () => {
    if (isLoading) {
      Utils.showSnackBar({
        message: "Cannot leave page while media file is uploading",
        severity: "error",
      });
    } else {
      navigate("/song-file");
    }
  };

  const handleDeleteLyrics = (id) => {
    dispatch(
      MediaAction.deleteMediaLyrics(id, () => {
        navigate("/song-file");
      })
    );
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (isLoading) {
        event.preventDefault();
        event.returnValue = ""; // Required for Chrome
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  useEffect(() => {
    const handleRouteChange = (event) => {
      if (isLoading) {
        event.preventDefault();
      }
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [isLoading]);

  useEffect(() => {
    if (!location?.state?.song || isEdit) {
      let fileReader,
        isCancel = false;
      if (state.thumbnail) {
        fileReader = new FileReader();
        fileReader.onload = (e) => {
          const { result } = e.target;
          if (result && !isCancel) {
            setImageThumbnail(result);
          }
        };
        if (state.thumbnail) {
          fileReader.readAsDataURL(state.thumbnail);
        }
      }
      return () => {
        isCancel = true;
        if (fileReader && fileReader.readyState === 1) {
          fileReader.abort();
        }
      };
    }
  }, [state.thumbnail, isEdit, imageThubnail]);

  return (
    <div className={styles.cardDiv}>
      <Row
        justify={"space-around"}
        style={{
          background: "#EFF4F8",
          boxShadow: "0px 3px 6px #AFC8E94D",
          padding: "25px 10px",
        }}
      >
        <Col
          xxl={11}
          xl={11}
          lg={11}
          md={22}
          sm={22}
          xs={22}
          className="flex-column"
        >
          <Text.Basic
            text="File Type"
            color={Colors.Black}
            fontSize={isMobile ? 18 : 16}
            fontWeight={600}
            fontFamily={Fonts[MontserratMedium]}
            textAlign="left"
          />
          <Row className="flex-row-space-center my-10">
            <Col className="flex-row mb-20 mt-10">
              <Button.Basic
                icon={
                  isAudioTab ? (
                    <img src={Images.ButtonOn} className="mr-5" />
                  ) : (
                    <Icon.FiCircle className="mr-5" fontSize={22} />
                  )
                }
                size={"large"}
                buttonStyle={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: isAudioTab ? Colors.BlueText : Colors.White,
                  border: isAudioTab ? "transparent" : "1px solid #D7DBE8",
                  borderRadius: "2px",
                  color: isAudioTab ? Colors.White : Colors.Black,
                }}
                onClick={() => handleChangeFileType(true)}
                text={"Audio"}
              />
              <Button.Basic
                icon={
                  !isAudioTab ? (
                    <img src={Images.ButtonOn} className="mr-5" />
                  ) : (
                    <Icon.FiCircle className="mr-5" fontSize={22} />
                  )
                }
                size={"large"}
                className="ml-10"
                buttonStyle={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: !isAudioTab ? Colors.BlueText : Colors.White,
                  borderRadius: "2px",
                  border: !isAudioTab ? "transparent" : "1px solid #D7DBE8",
                  color: !isAudioTab ? Colors.White : Colors.Black,
                }}
                onClick={() => handleChangeFileType(false)}
                text={"Video"}
              />
            </Col>
            <Col className={!isMobile && "mb-10"}>
              <Select.Checkbox
                label={"Make it downloadable"}
                checked={state.downloadable}
                size="large"
                onChange={(e) => {
                  setState({
                    ...state,
                    downloadable: e.target.checked,
                  });
                }}
              />
            </Col>
          </Row>
          {location?.state?.song ? (
            <div
              className={styles.thumbnailDiv}
              style={{
                background: `url(${imageThubnail})`,
              }}
              onClick={() => {
                location?.state?.song &&
                  window.open(
                    `https://nileurdata.s3.eu-west-2.amazonaws.com/${location?.state?.song?.thumbnail}`,
                    "_blank"
                  );
              }}
            ></div>
          ) : imageThubnail ? (
            <>
              <div
                className="drag-area-div bg-css"
                style={{
                  background: `url(${imageThubnail}) no-repeat`,
                  backgroundSize: "cover",
                }}
              >
                <Dragger
                  name={"file"}
                  multiple={false}
                  accept={isAudioTab ? "mp3,m4A,FLAC" : "mp4,MOV"}
                  progress={{ strokeWidth: 2, showInfo: false }}
                  showUploadList={false}
                  onChange={handleFileChange}
                  onDrop={handleFileDrop}
                  fileList={fileList}
                  customRequest={dummyRequest}
                >
                  <div className="video-background">
                    <p className="ant-upload-drag-icon">
                      <img
                        src={isAudioTab ? Images.AudioIcon : Images.VideoIcon}
                      />
                    </p>
                    <p className="ant-upload-text">
                      {isAudioTab
                        ? state.file && !error.fileError
                          ? "Uploaded an Audio"
                          : "Upload an Audio"
                        : state.file && !error.fileError
                        ? "Uploaded a Video"
                        : "Upload a Video"}
                    </p>
                    <p className="ant-upload-hint">
                      {isAudioTab
                        ? "Supported formats MP3, M4A, FLAC."
                        : "Supported formats MP4, MOV."}
                    </p>
                  </div>
                </Dragger>
              </div>
              {error.fileError && <Text.Error text={error.fileError} />}
            </>
          ) : (
            <>
              <Dragger
                name={"file"}
                customRequest={dummyRequest}
                multiple={false}
                accept={isAudioTab ? "mp3,m4A,FLAC" : "mp4,MOV"}
                progress={{ strokeWidth: 2, showInfo: false }}
                showUploadList={false}
                onChange={handleFileChange}
                onDrop={handleFileDrop}
                fileList={fileList}
              >
                <p className="ant-upload-drag-icon">
                  <img src={isAudioTab ? Images.AudioIcon : Images.VideoIcon} />
                </p>
                <p className="ant-upload-text">
                  {isAudioTab
                    ? state.file && !error.fileError
                      ? "Uploaded an Audio"
                      : "Upload an Audio"
                    : state.file && !error.fileError
                    ? "Uploaded a Video"
                    : "Upload a Video"}
                </p>
                <p className="ant-upload-hint">
                  {isAudioTab
                    ? "Supported formats MP3, M4A, FLAC."
                    : "Supported formats MP4, MOV."}
                </p>
              </Dragger>
              {error.fileError && <Text.Error text={error.fileError} />}
            </>
          )}
        </Col>
        <Col
          xxl={11}
          xl={11}
          lg={11}
          md={22}
          sm={22}
          xs={22}
          className={isTablet ? "flex-column" : "flex-column mt-20"}
        >
          <Row className="mb-20">
            <Col span={24}>
              <TextField.Basic
                label="Song Title*"
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                labelFontWeight={600}
                labelFontFamily={Fonts[MontserratMedium]}
                placeholder="Feel Your Arms"
                value={state.title}
                onChange={(e) => {
                  setState({
                    ...state,
                    title: e.target.value,
                  });
                  setError({
                    ...error,
                    titleError: "",
                  });
                }}
                errorText={error.titleError && error.titleError}
              />
            </Col>
          </Row>
          <Row className="mb-20 flex-row-space-between">
            <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
              <TextField.Basic
                label="Artist Name"
                labelFontWeight={600}
                labelFontFamily={Fonts[MontserratMedium]}
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                placeholder="Justin Bieber"
                value={state.artistName}
                onChange={(e) => {
                  setState({
                    ...state,
                    artistName: e.target.value,
                  });
                  setError({
                    ...error,
                    artistNameError: "",
                  });
                }}
                errorText={error.artistNameError && error.artistNameError}
              />
            </Col>
            <Col
              xxl={11}
              xl={11}
              lg={11}
              md={11}
              sm={24}
              xs={24}
              className={isMobile ? "flex-column" : "flex-column mt-20"}
            >
              <Text.Basic
                text="Category"
                color={Colors.Black}
                fontSize={isMobile ? 18 : 16}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
                className={isMobile ? "mb-10" : "mb-5"}
              />
              <TextField.SelectField
                inputStyle={{ width: "100%" }}
                size={isMobile && "large"}
                className="add-song-filter"
                value={state.category === "" ? "all" : state.category}
                allowClear={false}
                onChange={(e) => {
                  setState({
                    ...state,
                    category: e,
                  });
                }}
                options={[
                  {
                    label: "Select category",
                    value: "all",
                  },
                  {
                    label: "Promotion",
                    value: "Promotion",
                  },
                  {
                    label: "Nileur",
                    value: "Nileur",
                  },
                  {
                    label: "Divine Music",
                    value: "Divine-Music",
                  },
                  {
                    label: "Break & Groove",
                    value: "Break-and-Groove",
                  },
                  {
                    label: "Stratting",
                    value: "Stratting",
                  },
                  {
                    label: "Theeacting ",
                    value: "Theeacting ",
                  },
                  {
                    label: "Free Music Library",
                    value: "Free-Music-Library",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row className="mb-20">
            <Col span={24} className="flex-column">
              <Text.Basic
                text="Description*"
                color={Colors.Black}
                fontSize={isMobile ? 18 : 16}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
                className={isMobile ? "mb-10" : "mb-5"}
              />
              <TextField.Textarea
                labelFontWeight={600}
                labelFontFamily={Fonts[MontserratMedium]}
                size={"large"}
                className={"add-song-textarea"}
                rows={3}
                placeholder={
                  "All Time Low - Alternatif/Indie In publishing and graphic design"
                }
                value={state.description}
                onChange={(e) => {
                  setState({
                    ...state,
                    description: e.target.value,
                  });
                  setError({
                    ...error,
                    descriptionError: "",
                  });
                }}
              />
              {error.descriptionError && (
                <Text.Error text={error.descriptionError} />
              )}
            </Col>
          </Row>
          {location?.state?.song && (
            <Row className="mb-20 flex-row-space-between">
              <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
                <TextField.Basic
                  label="Upload Date: "
                  labelFontWeight={600}
                  labelFontFamily={Fonts[MontserratMedium]}
                  labelFontSize={isMobile ? 18 : 16}
                  labelClassName={isMobile ? "mb-10" : "mb-5"}
                  size={isMobile && "large"}
                  placeholder=""
                  disabled={true}
                  value={moment(location?.state?.song?.upload_date).format(
                    "DD MMM, YYYY"
                  )}
                />
              </Col>
              <Col
                xxl={11}
                xl={11}
                lg={11}
                md={11}
                sm={24}
                xs={24}
                className={isMobile ? "flex-column" : "flex-column mt-20"}
              >
                <TextField.Basic
                  label="Duration: "
                  labelFontWeight={600}
                  labelFontFamily={Fonts[MontserratMedium]}
                  labelFontSize={isMobile ? 18 : 16}
                  labelClassName={isMobile ? "mb-10" : "mb-5"}
                  size={isMobile && "large"}
                  placeholder=""
                  disabled={true}
                  value={duration}
                />
              </Col>
            </Row>
          )}
          <Row justify={"space-between"} className="mb-20">
            <Col span={location.state?.song?.lyrics ? 14 : 9}>
              <Row justify={"space-between"}>
                <Col
                  className={styles.uploadDiv}
                  span={location.state?.song?.lyrics ? 7 : 11}
                  onClick={() => imageFile.current.click()}
                >
                  <img src={Images.Upload} alt="thumbnail" className="mb-5" />
                  <Text.Basic
                    text={
                      state.thumbnail === null
                        ? "Upload Thumbnail"
                        : "Uploaded Thumbnail"
                    }
                    color={Colors.Black}
                    fontSize={12}
                    fontWeight={500}
                    fontFamily={Fonts[MontserratRegular]}
                  />
                  <input
                    type="file"
                    ref={imageFile}
                    onChange={handleImageFileChange}
                    accept="image/*"
                    className={styles.imageInput}
                  />
                </Col>
                {location.state?.song?.lyrics ? (
                  <Col span={16}>
                    <Row>
                      <Col
                        className={styles.uploadGroupDiv}
                        span={8}
                        onClick={() => {
                          location?.state?.song &&
                            window.open(
                              `https://nileurdata.s3.eu-west-2.amazonaws.com/${location?.state?.song?.lyrics}`,
                              "_blank"
                            );
                        }}
                      >
                        <Icon.FaEye
                          className="mb-5"
                          color="#afc8e9"
                          size={35}
                        />
                        <Text.Basic
                          text={"View Lyrics"}
                          color={Colors.Black}
                          fontSize={12}
                          fontWeight={500}
                          fontFamily={Fonts[MontserratRegular]}
                        />
                      </Col>
                      <Col
                        className={styles.uploadGroupDiv}
                        span={8}
                        onClick={() => SRTFile.current.click()}
                      >
                        <img
                          src={Images.Upload}
                          alt="thumbnail"
                          className="mb-10"
                        />
                        <Text.Basic
                          text={"Replace Lyrics"}
                          color={Colors.Black}
                          fontSize={12}
                          fontWeight={500}
                          fontFamily={Fonts[MontserratRegular]}
                        />
                        <input
                          type="file"
                          ref={SRTFile}
                          onChange={handleSRTFileChange}
                          className={styles.imageInput}
                        />
                      </Col>
                      <Popconfirm
                        title="Delete Lyrics"
                        description="Are you sure you want to delete this lyrics file?"
                        onConfirm={() =>
                          handleDeleteLyrics(location?.state?.song?.id)
                        }
                        icon={<Icon.CiCircleRemove size={20} color="red" />}
                        placement="topRight"
                        okText="Yes"
                        cancelText="No"
                      >
                        <Col className={styles.uploadGroupDiv} span={8}>
                          <Icon.AiFillDelete
                            className="mb-5"
                            color={"#afc8e9"}
                            size={34}
                          />
                          <Text.Basic
                            text={"Remove Lyrics"}
                            color={Colors.Black}
                            fontSize={12}
                            fontWeight={500}
                            fontFamily={Fonts[MontserratRegular]}
                          />
                        </Col>
                      </Popconfirm>
                    </Row>
                  </Col>
                ) : (
                  <Col
                    className={styles.uploadDiv}
                    span={11}
                    onClick={() => SRTFile.current.click()}
                  >
                    <img src={Images.Upload} alt="thumbnail" className="mb-5" />
                    <Text.Basic
                      text={
                        state.lyrics === null
                          ? "Upload Lyrics"
                          : "Uploaded Lyrics"
                      }
                      color={Colors.Black}
                      fontSize={12}
                      fontWeight={500}
                      fontFamily={Fonts[MontserratRegular]}
                    />
                    <input
                      type="file"
                      ref={SRTFile}
                      onChange={handleSRTFileChange}
                      className={styles.imageInput}
                    />
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={isLoading ? 10 : 9}>
              <Row justify={"space-between"}>
                <Col>
                  <Button.Basic
                    size={"large"}
                    buttonStyle={{
                      backgroundColor: Colors.Black,
                      borderRadius: "2px",
                      color: Colors.White,
                    }}
                    text={"Cancel"}
                    onClick={handleCancelSubmit}
                  />
                </Col>
                <Col>
                  <Button.Basic
                    size={"large"}
                    buttonStyle={{
                      backgroundColor: Colors.BlueText,
                      borderRadius: "2px",
                      color: Colors.White,
                    }}
                    loading={isLoading}
                    disabled={isLoading}
                    text={"Upload"}
                    onClick={handleSubmitMedia}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      {showPrompt && (
        <Modal.Confirmation
          open={showPrompt}
          isShowCancelButton={true}
          cancelButtonText={"Cancel"}
          onClickSave={confirmNavigation}
          onClickCancel={cancelNavigation}
          isShowSaveButton={false}
          ButtonText="Leave"
          closeable={false}
          title="Page Leave Confirmation"
        >
          <Row justify={"center"}>
            <Text.Basic
              text="Your media upload progress will be discarded if you leave the page"
              color={Colors.Black}
              fontSize={16}
              fontWeight={600}
              fontFamily={Fonts[MontserratMedium]}
            />
          </Row>
        </Modal.Confirmation>
      )}
    </div>
  );
}

export default AddSong;
