import {
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_FAILURE,
  CHANGE_USER_STATUS_SUCCESS,
  GET_USER_LISTING,
  GET_USER_LISTING_FAILURE,
  GET_USER_LISTING_SUCCESS,
  GET_USER_LOCATIONS,
  GET_USER_LOCATIONS_FAILURE,
  GET_USER_LOCATIONS_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  locationLoader: false,
  locations: [],
  users: [],
  count: 0,
  deleted: 0,
  user: {},
  error: null,
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_LISTING:
    case CHANGE_USER_STATUS:
      state = {
        users: [],
        locations: [],
        count: 0,
        deleted: 0,
        user: {},
        error: null,
        isLoading: true,
      };
      break;
    case GET_USER_LISTING_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        count: action.payload.total,
        users: action.payload.records,
        deleted: action.payload.deleted,
      };
      break;
    case GET_USER_LOCATIONS:
      state = {
        ...state,
        locationLoader: true,
        error: null,
      };
      break;
    case GET_USER_LOCATIONS_SUCCESS:
      const payload = action.payload.map((item) => {
        return {
          label: `${item.name} (${item.type})`,
          value: `${item.name}_${item.type}_${item.id}`,
        };
      });
      state = {
        ...state,
        locationLoader: false,
        locations: payload,
      };
      break;
    case CHANGE_USER_STATUS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        error: null,
      };
      break;
    case GET_USER_LISTING_FAILURE:
    case CHANGE_USER_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    case GET_USER_LOCATIONS_FAILURE:
      state = {
        ...state,
        locationLoader: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
