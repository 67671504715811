import {
  ADD_PROMO_CODE,
  ADD_PROMO_CODE_FAILURE,
  ADD_PROMO_CODE_SUCCESS,
  CHANGE_PROMO_CODE_STATUS,
  CHANGE_PROMO_CODE_STATUS_FAILURE,
  CHANGE_PROMO_CODE_STATUS_SUCCESS,
  DELETE_PROMO_CODE,
  DELETE_PROMO_CODE_FAILURE,
  DELETE_PROMO_CODE_SUCCESS,
  GET_PROMO_CODES,
  GET_PROMO_CODES_FAILURE,
  GET_PROMO_CODES_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  promoCodes: [],
  count: 0,
  promoCode: {},
  error: null,
};

export default function PromoCodeReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PROMO_CODES:
    case CHANGE_PROMO_CODE_STATUS:
    case DELETE_PROMO_CODE:
    case ADD_PROMO_CODE:
      state = {
        ...state,
        error: null,
        isLoading: true,
      };
      break;
    case GET_PROMO_CODES_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        count: action.payload.total,
        promoCodes: action.payload.records,
      };
      break;
    case CHANGE_PROMO_CODE_STATUS_SUCCESS:
    case DELETE_PROMO_CODE_SUCCESS:
    case ADD_PROMO_CODE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        error: null,
      };
      break;
    case GET_PROMO_CODES_FAILURE:
    case DELETE_PROMO_CODE_FAILURE:
    case ADD_PROMO_CODE_FAILURE:
    case CHANGE_PROMO_CODE_STATUS_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
