import {
  DELETE_FEEDBACK,
  DELETE_FEEDBACK_FAILURE,
  DELETE_FEEDBACK_SUCCESS,
  GET_FEEDBACKS,
  GET_FEEDBACKS_FAILURE,
  GET_FEEDBACKS_SUCCESS,
  REPLY_FEEDBACK,
  REPLY_FEEDBACK_FAILURE,
  REPLY_FEEDBACK_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  feedbacks: [],
  count: 0,
  feedback: {},
  error: null,
};

export default function FeedbackReducer(state = initialState, action) {
  switch (action.type) {
    case GET_FEEDBACKS:
    case DELETE_FEEDBACK:
    case REPLY_FEEDBACK:
      state = {
        feedbacks: [],
        count: 0,
        feedback: {},
        error: null,
        isLoading: true,
      };
      break;
    case GET_FEEDBACKS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        count: action.payload?.total,
        feedbacks: action.payload?.records,
      };
      break;
    case DELETE_FEEDBACK_SUCCESS:
    case REPLY_FEEDBACK_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        error: null,
      };
      break;
    case GET_FEEDBACKS_FAILURE:
    case DELETE_FEEDBACK_FAILURE:
    case REPLY_FEEDBACK_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      break;
    default:
      break;
  }

  return state;
}
