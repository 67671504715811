//dev
// var BASE_URL = "https://nileur.5stardesigners.net/dev/nileur_api/public/api";

//qa
// var BASE_URL = "https://nileur.5stardesigners.net/qa/nileur_api/public/api";

//live 
var BASE_URL = "https://api.nileurmusic.com/api";

const configVariables = {
  BASE_URL,
};

const constantVariables = {
  TempUser: {
    name: "David Smith",
    email: "david@yopmail.com",
    pass: "asd123",
    userId: 12,
  },
};

export default {
  ...configVariables,
  ...constantVariables,
};
