import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useMedia } from "use-media";
import React, { useState } from "react";

import { Button, Text, TextField } from "../../components";
import { Colors, Images, Utils } from "../../config";
import LoginBox from "../../components/Layout/LoginBox";

import Fonts, {
  MontserratLight,
  MontserratMedium,
  PoppinsSemiBold,
} from "../../config/fonts";
import { AuthAction } from "../../store/actions";
import styles from "./styles";

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const isLoading = useSelector((state) => state.auth.resetLoader);
  const [state, setState] = useState({
    password: "",
    confirmPassword: "",
    passwordError: "",
    confirmPasswordError: "",
  });

  const handleResetPassword = () => {
    if (!state.password) {
      setState({
        ...state,
        passwordError: "Please enter password",
      });
    } else if (!Utils.passwordRegex.test(state.password)) {
      setState({
        ...state,
        passwordError:
          "Password should be atleast 8 characters long with 1 lowercase. 1 uppercase and 1 special character",
      });
    } else if (!state.confirmPassword) {
      setState({
        ...state,
        confirmPasswordError: "Please confirm password",
      });
    } else if (!Utils.passwordRegex.test(state.confirmPassword)) {
      setState({
        ...state,
        confirmPasswordError:
          "Password should be atleast 8 characters long with 1 lowercase. 1 uppercase and 1 special character",
      });
    } else if (state.password !== state.confirmPassword) {
      setState({
        ...state,
        confirmPasswordError: "Passwords do not match.",
      });
    } else {
      setState({
        ...state,
        passwordError: "",
        confirmPasswordError: "",
      });
      const payload = {
        email: "admin_nileur@yopmail.com",
        verification_code: location.state.otpCode,
        password: state.password,
      };
      dispatch(AuthAction.resetPassword(payload, () => navigate("/login")));
    }
  };

  return (
    <LoginBox>
      <Col lg={24} md={24} sm={24} xs={24} style={styles.flex}>
        <Text.Basic
          text="Forgot password"
          fontWeight={"bold"}
          fontSize={isMobile ? 36 : isSmallMobile ? 28 : 24}
          textAlign="center"
          fontFamily={Fonts[MontserratMedium]}
          color={Colors.Black}
          className="mb-5"
        />
        <Text.Basic
          text="Set the new password for your account"
          fontSize={isMobile ? 16 : isSmallMobile ? 14 : 13}
          textAlign="center"
          fontFamily={Fonts[MontserratLight]}
          color={Colors.Black}
        />
      </Col>
      <Col span={24} className={!isMobile && "my-20"}>
        <TextField.Basic
          type={"password"}
          labelFontSize={isMobile ? 18 : 16}
          labelClassName={isMobile ? "mb-10" : "mb-5"}
          size={isMobile ? "large" : "medium"}
          value={state.password}
          placeholder={"Password"}
          color={Colors.inputText}
          password
          onChange={(e) =>
            setState({
              ...state,
              password: e.target.value,
              passwordError: "",
            })
          }
          label="New Password"
          suffix={<img src={Images.Lock} alt="lock" width={16} />}
          errorText={state.passwordError && state.passwordError}
        />
      </Col>
      <Col span={24} className={!isMobile && "mb-20"}>
        <TextField.Basic
          type={"password"}
          labelFontSize={isMobile ? 18 : 16}
          labelClassName={isMobile ? "mb-10" : "mb-5"}
          size={isMobile ? "large" : "medium"}
          value={state.confirmPassword}
          placeholder={"Password"}
          color={Colors.inputText}
          password
          onChange={(e) =>
            setState({
              ...state,
              confirmPassword: e.target.value,
              confirmPasswordError: "",
            })
          }
          label="Confirm Password"
          suffix={<img src={Images.Lock} alt="lock" width={16} />}
          errorText={state.confirmPasswordError && state.confirmPasswordError}
        />
      </Col>
      <Col span={24} className={!isMobile && "mb-20"}>
        <Row justify={"center"}>
          <Col span={24}>
            <Button.Basic
              text="Update"
              size={isMobile ? "large" : "medium"}
              onClick={handleResetPassword}
              loading={isLoading}
              disabled={isLoading}
              buttonStyle={{
                width: "100%",
                height: "45px",
                backgroundColor: "#7836FF",
                color: "white",
                fontSize: "20px",
                fontFamily: Fonts[PoppinsSemiBold],
                borderRadius: "8px",
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} style={styles.flex}>
        <Text.Basic
          text="Sign in"
          fontSize={16}
          textAlign="center"
          cursor={"pointer"}
          fontFamily={Fonts[MontserratLight]}
          color={Colors.BlackText}
          onClick={() => navigate("/login")}
        />
      </Col>
    </LoginBox>
  );
}

export default ResetPassword;
