//auth
export const SIGN_IN = "SIGN_IN";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

//profile
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

//media file
export const GET_MEDIA_LISTING = "GET_MEDIA_LISTING";
export const GET_MEDIA_LISTING_SUCCESS = "GET_MEDIA_LISTING_SUCCESS";
export const GET_MEDIA_LISTING_FAILURE = "GET_MEDIA_LISTING_FAILURE";

export const DELETE_MEDIA = "DELETE_MEDIA";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
export const DELETE_MEDIA_FAILURE = "DELETE_MEDIA_FAILURE";

export const ADD_MEDIA = "ADD_MEDIA";
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";
export const ADD_MEDIA_FAILURE = "ADD_MEDIA_FAILURE";

export const EDIT_MEDIA = "EDIT_MEDIA";
export const EDIT_MEDIA_SUCCESS = "EDIT_MEDIA_SUCCESS";
export const EDIT_MEDIA_FAILURE = "EDIT_MEDIA_FAILURE";

export const DELETE_MEDIA_LYRICS = "DELETE_MEDIA_LYRICS";
export const DELETE_MEDIA_LYRICS_SUCCESS = "DELETE_MEDIA_LYRICS_SUCCESS";
export const DELETE_MEDIA_LYRICS_FAILURE = "DELETE_MEDIA_LYRICS_FAILURE";

//user management
export const GET_USER_LISTING = "GET_USER_LISTING";
export const GET_USER_LISTING_SUCCESS = "GET_USER_LISTING_SUCCESS";
export const GET_USER_LISTING_FAILURE = "GET_USER_LISTING_FAILURE";

export const GET_USER_LOCATIONS = "GET_USER_LOCATIONS";
export const GET_USER_LOCATIONS_SUCCESS = "GET_USER_LOCATIONS_SUCCESS";
export const GET_USER_LOCATIONS_FAILURE = "GET_USER_LOCATIONS_FAILURE";

export const CHANGE_USER_STATUS = "CHANGE_USER_STATUS";
export const CHANGE_USER_STATUS_SUCCESS = "CHANGE_USER_STATUS_SUCCESS";
export const CHANGE_USER_STATUS_FAILURE = "CHANGE_USER_STATUS_FAILURE";

//dashboard
export const GET_USERS_COUNT = "GET_USERS_COUNT";
export const GET_USERS_COUNT_SUCCESS = "GET_USERS_COUNT_SUCCESS";
export const GET_USERS_COUNT_FAILURE = "GET_USERS_COUNT_FAILURE";

export const GET_SUBSCRIPTIONS_COUNT = "GET_SUBSCRIPTIONS_COUNT";
export const GET_SUBSCRIPTIONS_COUNT_SUCCESS =
  "GET_SUBSCRIPTIONS_COUNT_SUCCESS";
export const GET_SUBSCRIPTIONS_COUNT_FAILURE =
  "GET_SUBSCRIPTIONS_COUNT_FAILURE";

export const GET_MEDIA_COUNT = "GET_MEDIA_COUNT";
export const GET_MEDIA_COUNT_SUCCESS = "GET_MEDIA_COUNT_SUCCESS";
export const GET_MEDIA_COUNT_FAILURE = "GET_MEDIA_COUNT_FAILURE";

export const GET_STREAMS_COUNT = "GET_STREAMS_COUNT";
export const GET_STREAMS_COUNT_SUCCESS = "GET_STREAMS_COUNT_SUCCESS";
export const GET_STREAMS_COUNT_FAILURE = "GET_STREAMS_COUNT_FAILURE";

export const GET_AUDIO_PURCHASES = "GET_AUDIO_PURCHASES";
export const GET_AUDIO_PURCHASES_SUCCESS = "GET_AUDIO_PURCHASES_SUCCESS";
export const GET_AUDIO_PURCHASES_FAILURE = "GET_AUDIO_PURCHASES_FAILURE";

export const GET_VIDEO_PURCHASES = "GET_VIDEO_PURCHASES";
export const GET_VIDEO_PURCHASES_SUCCESS = "GET_VIDEO_PURCHASES_SUCCESS";
export const GET_VIDEO_PURCHASES_FAILURE = "GET_VIDEO_PURCHASES_FAILURE";

export const GET_USER_SIGNUP_STATS = "GET_USER_SIGNUP_STATS";
export const GET_USER_SIGNUP_STATS_SUCCESS = "GET_USER_SIGNUP_STATS_SUCCESS";
export const GET_USER_SIGNUP_STATS_FAILURE = "GET_USER_SIGNUP_STATS_FAILURE";

export const GET_MEDIA_TYPE_STATS = "GET_MEDIA_TYPE_STATS";
export const GET_MEDIA_TYPE_STATS_SUCCESS = "GET_MEDIA_TYPE_STATS_SUCCESS";
export const GET_MEDIA_TYPE_STATS_FAILURE = "GET_MEDIA_TYPE_STATS_FAILURE";

export const GET_MEDIA_PLAY_COUNT_STATS = "GET_MEDIA_PLAY_COUNT_STATS";
export const GET_MEDIA_PLAY_COUNT_STATS_SUCCESS =
  "GET_MEDIA_PLAY_COUNT_STATS_SUCCESS";
export const GET_MEDIA_PLAY_COUNT_STATS_FAILURE =
  "GET_MEDIA_PLAY_COUNT_STATS_FAILURE";

export const GET_USER_LOCATION_STATS = "GET_USER_LOCATION_STATS";
export const GET_USER_LOCATION_STATS_SUCCESS =
  "GET_USER_LOCATION_STATS_SUCCESS";
export const GET_USER_LOCATION_STATS_FAILURE =
  "GET_USER_LOCATION_STATS_FAILURE";

//feedbacks
export const GET_FEEDBACKS = "GET_FEEDBACKS";
export const GET_FEEDBACKS_SUCCESS = "GET_FEEDBACKS_SUCCESS";
export const GET_FEEDBACKS_FAILURE = "GET_FEEDBACKS_FAILURE";

export const DELETE_FEEDBACK = "DELETE_FEEDBACK";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_FAILURE = "DELETE_FEEDBACK_FAILURE";

export const REPLY_FEEDBACK = "REPLY_FEEDBACK";
export const REPLY_FEEDBACK_SUCCESS = "REPLY_FEEDBACK_SUCCESS";
export const REPLY_FEEDBACK_FAILURE = "REPLY_FEEDBACK_FAILURE";

//promo code
export const GET_PROMO_CODES = "GET_PROMO_CODES";
export const GET_PROMO_CODES_SUCCESS = "GET_PROMO_CODES_SUCCESS";
export const GET_PROMO_CODES_FAILURE = "GET_PROMO_CODES_FAILURE";

export const CHANGE_PROMO_CODE_STATUS = "CHANGE_PROMO_CODE_STATUS";
export const CHANGE_PROMO_CODE_STATUS_SUCCESS =
  "CHANGE_PROMO_CODE_STATUS_SUCCESS";
export const CHANGE_PROMO_CODE_STATUS_FAILURE =
  "CHANGE_PROMO_CODE_STATUS_FAILURE";

export const DELETE_PROMO_CODE = "DELETE_PROMO_CODE";
export const DELETE_PROMO_CODE_SUCCESS = "DELETE_PROMO_CODE_SUCCESS";
export const DELETE_PROMO_CODE_FAILURE = "DELETE_PROMO_CODE_FAILURE";

export const ADD_PROMO_CODE = "ADD_PROMO_CODE";
export const ADD_PROMO_CODE_SUCCESS = "ADD_PROMO_CODE_SUCCESS";
export const ADD_PROMO_CODE_FAILURE = "ADD_PROMO_CODE_FAILURE";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";
