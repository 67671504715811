import {
  GET_AUDIO_PURCHASES,
  GET_AUDIO_PURCHASES_FAILURE,
  GET_AUDIO_PURCHASES_SUCCESS,
  GET_MEDIA_COUNT,
  GET_MEDIA_COUNT_FAILURE,
  GET_MEDIA_COUNT_SUCCESS,
  GET_MEDIA_PLAY_COUNT_STATS,
  GET_MEDIA_PLAY_COUNT_STATS_FAILURE,
  GET_MEDIA_PLAY_COUNT_STATS_SUCCESS,
  GET_MEDIA_TYPE_STATS,
  GET_MEDIA_TYPE_STATS_FAILURE,
  GET_MEDIA_TYPE_STATS_SUCCESS,
  GET_STREAMS_COUNT,
  GET_STREAMS_COUNT_FAILURE,
  GET_STREAMS_COUNT_SUCCESS,
  GET_SUBSCRIPTIONS_COUNT,
  GET_SUBSCRIPTIONS_COUNT_FAILURE,
  GET_SUBSCRIPTIONS_COUNT_SUCCESS,
  GET_USERS_COUNT,
  GET_USERS_COUNT_FAILURE,
  GET_USERS_COUNT_SUCCESS,
  GET_USER_LOCATION_STATS,
  GET_USER_LOCATION_STATS_FAILURE,
  GET_USER_LOCATION_STATS_SUCCESS,
  GET_USER_SIGNUP_STATS,
  GET_USER_SIGNUP_STATS_FAILURE,
  GET_USER_SIGNUP_STATS_SUCCESS,
  GET_VIDEO_PURCHASES,
  GET_VIDEO_PURCHASES_FAILURE,
  GET_VIDEO_PURCHASES_SUCCESS,
} from "../constants";

export default class DashboardAction {
  static getUsersCount(payload, cb) {
    return {
      type: GET_USERS_COUNT,
      payload,
      cb,
    };
  }
  static getUsersCountSuccess(payload) {
    return {
      type: GET_USERS_COUNT_SUCCESS,
      payload,
    };
  }
  static getUsersCountFailure() {
    return {
      type: GET_USERS_COUNT_FAILURE,
    };
  }

  static getSubscriptionsCount(payload, cb) {
    return {
      type: GET_SUBSCRIPTIONS_COUNT,
      payload,
      cb,
    };
  }
  static getSubscriptionsCountSuccess(payload) {
    return {
      type: GET_SUBSCRIPTIONS_COUNT_SUCCESS,
      payload,
    };
  }
  static getSubscriptionsCountFailure() {
    return {
      type: GET_SUBSCRIPTIONS_COUNT_FAILURE,
    };
  }

  static getMediaCount(payload, cb) {
    return {
      type: GET_MEDIA_COUNT,
      payload,
      cb,
    };
  }
  static getMediaCountSuccess(payload) {
    return {
      type: GET_MEDIA_COUNT_SUCCESS,
      payload,
    };
  }
  static getMediaCountFailure() {
    return {
      type: GET_MEDIA_COUNT_FAILURE,
    };
  }

  static getStreamsCount(payload, cb) {
    return {
      type: GET_STREAMS_COUNT,
      payload,
      cb,
    };
  }
  static getStreamsCountSuccess(payload) {
    return {
      type: GET_STREAMS_COUNT_SUCCESS,
      payload,
    };
  }
  static getStreamsCountFailure() {
    return {
      type: GET_STREAMS_COUNT_FAILURE,
    };
  }

  static getAudioPurchases(payload, cb) {
    return {
      type: GET_AUDIO_PURCHASES,
      payload,
      cb,
    };
  }
  static getAudioPurchasesSuccess(payload) {
    return {
      type: GET_AUDIO_PURCHASES_SUCCESS,
      payload,
    };
  }
  static getAudioPurchasesFailure() {
    return {
      type: GET_AUDIO_PURCHASES_FAILURE,
    };
  }

  static getVideoPurchases(payload, cb) {
    return {
      type: GET_VIDEO_PURCHASES,
      payload,
      cb,
    };
  }
  static getVideoPurchasesSuccess(payload) {
    return {
      type: GET_VIDEO_PURCHASES_SUCCESS,
      payload,
    };
  }
  static getVideoPurchasesFailure() {
    return {
      type: GET_VIDEO_PURCHASES_FAILURE,
    };
  }

  static getUserSignupStats(payload, cb) {
    return {
      type: GET_USER_SIGNUP_STATS,
      payload,
      cb,
    };
  }
  static getUserSignupStatsSuccess(payload) {
    return {
      type: GET_USER_SIGNUP_STATS_SUCCESS,
      payload,
    };
  }
  static getUserSignupStatsFailure() {
    return {
      type: GET_USER_SIGNUP_STATS_FAILURE,
    };
  }

  static getMediaTypeStats(payload, cb) {
    return {
      type: GET_MEDIA_TYPE_STATS,
      payload,
      cb,
    };
  }
  static getMediaTypeStatsSuccess(payload) {
    return {
      type: GET_MEDIA_TYPE_STATS_SUCCESS,
      payload,
    };
  }
  static getMediaTypeStatsFailure() {
    return {
      type: GET_MEDIA_TYPE_STATS_FAILURE,
    };
  }

  static getMediaPlayCountStats(payload, cb) {
    return {
      type: GET_MEDIA_PLAY_COUNT_STATS,
      payload,
      cb,
    };
  }
  static getMediaPlayCountStatsSuccess(payload) {
    return {
      type: GET_MEDIA_PLAY_COUNT_STATS_SUCCESS,
      payload,
    };
  }
  static getMediaPlayCountStatsFailure() {
    return {
      type: GET_MEDIA_PLAY_COUNT_STATS_FAILURE,
    };
  }

  static getUserLocationStats(payload, cb) {
    return {
      type: GET_USER_LOCATION_STATS,
      payload,
      cb,
    };
  }
  static getUserLocationStatsSuccess(payload) {
    return {
      type: GET_USER_LOCATION_STATS_SUCCESS,
      payload,
    };
  }
  static getUserLocationStatsFailure() {
    return {
      type: GET_USER_LOCATION_STATS_FAILURE,
    };
  }
}
