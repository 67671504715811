import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  LOADER_FALSE,
  LOADER_TRUE,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
} from "../constants";

const initialState = {
  isLoading: false,
  loginLoader: false,
  verifyLoader: false,
  resetLoader: false,
  changeLoader: false,
  isLoggedIn: localStorage.getItem("authUser") ? true : false,
  user: localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser"))
    : {},
  error: null,
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case SIGN_IN:
      state = {
        ...state,
        loginLoader: true,
        error: null,
      };
      break;
    case SIGN_IN_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        loginLoader: false,
        isLoggedIn: true,
      };
      break;
    case SIGN_IN_FAILURE:
      state = {
        ...state,
        loginLoader: false,
        isLoggedIn: false,
        error: action.payload,
      };
      break;
    case FORGOT_PASSWORD:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case FORGOT_PASSWORD_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case FORGOT_PASSWORD_FAILURE:
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case VERIFY_OTP:
      state = {
        ...state,
        verifyLoader: true,
        error: null,
      };
      break;
    case VERIFY_OTP_SUCCESS:
      state = {
        ...state,
        verifyLoader: false,
      };
      break;
    case VERIFY_OTP_FAILURE:
      state = {
        ...state,
        verifyLoader: false,
        error: action.payload,
      };
      break;
    case RESET_PASSWORD:
      state = {
        ...state,
        resetLoader: true,
        error: null,
      };
      break;
    case RESET_PASSWORD_SUCCESS:
      state = {
        ...state,
        resetLoader: false,
      };
      break;
    case RESET_PASSWORD_FAILURE:
      state = {
        ...state,
        resetLoader: false,
        error: action.payload,
      };
      break;
    case CHANGE_PASSWORD:
      state = {
        ...state,
        changeLoader: true,
        error: null,
      };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        changeLoader: false,
      };
      break;
    case CHANGE_PASSWORD_FAILURE:
      state = {
        ...state,
        changeLoader: false,
        error: action.payload,
      };
      break;
    case LOGOUT:
      state = {
        ...state,
        isLoading: true,
        error: null,
      };
      break;
    case LOGOUT_SUCCESS:
      state = {
        user: {},
        isLoading: false,
        isLoggedIn: false,
      };
      break;
    case LOGOUT_FAILURE:
      state = {
        ...state,
        isLoading: false,
        isLoggedIn: false,
        error: action.payload?.message,
      };
      break;
    case LOADER_TRUE:
      state = {
        ...state,
        loader: true,
        error: null,
      };
      break;

    case LOADER_FALSE:
      state = {
        ...state,
        loader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
