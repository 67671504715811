import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { UserAction } from "../actions";

export default class UserMiddleware {
  static *GetUserListing({ payload, cb }) {
    try {
      const {
        limit = 10,
        offset = 0,
        keyword = "",
        status = "",
        location_Id = "",
        location_type = "",
      } = payload;
      let res = yield ApiCaller.Get(
        `/users?offset=${offset}&keyword=${keyword}&limit=${limit}&status=${status}&location_Id=${location_Id}&location_type=${location_type}`
      );
      if (res.status == 200) {
        yield put(UserAction.getUserListingSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(UserAction.getUserListingFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(UserAction.getUserListingFailure());
      //   Utils.showSnackBar({
      //     message: "Reset password failed",
      //     severity: "error",
      //   });
    }
  }

  static *ChangeUserStatus({ id, cb }) {
    try {
      let res = yield ApiCaller.Put(`/users/status/toggle?user_id=${id}`);
      if (res.status == 201) {
        yield put(UserAction.changeUserStatusSuccess(res.data.data));
        Utils.showSnackBar({
          message: res.data.message,
        });
        if (cb) {
          cb();
        }
      } else {
        yield put(UserAction.changeUserStatusFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(yield put(UserAction.changeUserStatusFailure()));
    }
  }

  static *GetUserLocations({ keyword, cb }) {
    try {
      let res = yield ApiCaller.Get(
        `/location/search?limit=30&offset=0&keyword=${keyword}`
      );
      if (res.status == 200) {
        yield put(UserAction.getUserLocationsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(UserAction.getUserLocationsFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(UserAction.getUserLocationsFailure());
    }
  }
}
