import { Route, Routes } from "react-router-dom";
import React from "react";

import { PROTECTED_ROUTES } from "./siteMap";
import DashboardLayout from "../../components/Layout/dashboardLayout";

const AppRoutes = (props) => {
  return (
    <DashboardLayout expandible={false}>
      <Routes>
        {PROTECTED_ROUTES.map((route) => (
          <Route path={route.path} element={route.component} />
        ))}
      </Routes>
    </DashboardLayout>
  );
};

export default AppRoutes;
