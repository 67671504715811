import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";

import "antd/dist/reset.css";

import App from "./App.jsx";
import reportWebVitals from "./reportWebVitals";
import Store from "./store";

import "./index.css";

ReactDOM.render(
  <Router basename="/">
    <Provider store={Store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
