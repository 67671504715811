import { Col, Row, Skeleton } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import CountUp from "react-countup";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

import { Charts, Picker, Select, Text, TextField } from "../../components";
import { Colors } from "../../config";

import Fonts, {
  MontserratBold,
  MontserratLight,
  MontserratMedium,
  MontserratRegular,
} from "../../config/fonts";
import { DashboardAction, MediaAction } from "../../store/actions";
import styles from "./index.module.css";

function Dashboard() {
  const dispatch = useDispatch();
  const isLaptop = useMedia({ minWidth: "1050px" });
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const dashboard = useSelector((state) => state.dashboard);
  const mediaListing = useSelector((state) => state.media);
  const [playedSongData, setPlayedSongData] = useState([]);
  const [cardCountFilter, setCardCountFilter] = useState({
    startDate: dayjs(new Date()).subtract(120, "day").format("MM/DD/YYYY"),
    endDate: dayjs(new Date()).format("MM/DD/YYYY"),
  });
  const [signUpFilter, setSignUpFilter] = useState({
    startDate: dayjs(new Date()).subtract(120, "day").format("MM/DD/YYYY"),
    endDate: dayjs(new Date()).format("MM/DD/YYYY"),
  });
  const [mediaDateFilter, setMediaDateFilter] = useState({
    startDate: dayjs(new Date()).subtract(120, "day").format("MM/DD/YYYY"),
    endDate: dayjs(new Date()).format("MM/DD/YYYY"),
  });
  const [userLocationDateFilter, setUserLocationDateFilter] = useState({
    startDate: dayjs(new Date()).subtract(120, "day").format("MM/DD/YYYY"),
    endDate: dayjs(new Date()).format("MM/DD/YYYY"),
  });
  const [mediaCategoryFilter, setMediaCategoryFilter] = useState("");
  const [playedSongCategoryFilter, setPlayedSongCategoryFilter] = useState("");
  const [playedSongDateFilter, setPlayedSongDateFilter] = useState({
    startDate: dayjs(new Date()).subtract(120, "day").format("MM/DD/YYYY"),
    endDate: dayjs(new Date()).format("MM/DD/YYYY"),
  });

  const handleFilterDateChange = (date, type) => {
    if (date && type === "user signup stats") {
      setSignUpFilter({
        startDate: dayjs(date[0]).format("MM/DD/YYYY"),
        endDate: dayjs(date[1]).format("MM/DD/YYYY"),
      });
    } else if (date && type === "media types stats") {
      setMediaDateFilter({
        startDate: dayjs(date[0]).format("MM/DD/YYYY"),
        endDate: dayjs(date[1]).format("MM/DD/YYYY"),
      });
    } else if (date && type === "played song stats") {
      setPlayedSongDateFilter({
        startDate: dayjs(date[0]).format("MM/DD/YYYY"),
        endDate: dayjs(date[1]).format("MM/DD/YYYY"),
      });
    } else if (date && type === "user location stats") {
      setUserLocationDateFilter({
        startDate: dayjs(date[0]).format("MM/DD/YYYY"),
        endDate: dayjs(date[1]).format("MM/DD/YYYY"),
      });
    }
  };

  const handleFilterPlayedSongType = (e) => {
    setPlayedSongCategoryFilter(e);
    if (e === "audio") {
      const filteredData = dashboard?.mediaPlayCountStats?.filter(
        (item) => item.type === "audio"
      );
      setPlayedSongData(filteredData);
    } else if (e === "video") {
      const filteredData = dashboard?.mediaPlayCountStats?.filter(
        (item) => item.type === "video"
      );
      setPlayedSongData(filteredData);
    } else {
      setPlayedSongCategoryFilter("");
    }
  };

  useEffect(() => {
    const popularMediaPayload = {
      limit: 10,
      offset: 0,
      sort_by: "play_count",
      order: "desc",
    };
    dispatch(MediaAction.getMediaListing(popularMediaPayload));
  }, []);

  useEffect(() => {
    const payload = {
      start_date: cardCountFilter.startDate,
      end_date: cardCountFilter.endDate,
    };
    dispatch(DashboardAction.getUsersCount(payload));
    dispatch(DashboardAction.getSubscriptionsCount(payload));
    dispatch(DashboardAction.getMediaCount(payload));
    dispatch(DashboardAction.getStreamsCount(payload));
    dispatch(DashboardAction.getAudioPurchases(payload));
    dispatch(DashboardAction.getVideoPurchases(payload));
  }, [cardCountFilter]);

  useEffect(() => {
    const signupPayload = {
      start_date: signUpFilter.startDate,
      end_date: signUpFilter.endDate,
    };
    dispatch(DashboardAction.getUserSignupStats(signupPayload));
  }, [signUpFilter]);

  useEffect(() => {
    const mediaTypePayload = {
      start_date: mediaDateFilter.startDate,
      end_date: mediaDateFilter.endDate,
      media_category: mediaCategoryFilter,
    };
    dispatch(DashboardAction.getMediaTypeStats(mediaTypePayload));
  }, [mediaCategoryFilter, mediaDateFilter]);

  useEffect(() => {
    const mediaPlayCountPayload = {
      start_date: playedSongDateFilter.startDate,
      end_date: playedSongDateFilter.endDate,
      media_category: encodeURIComponent(playedSongCategoryFilter),
    };
    dispatch(DashboardAction.getMediaPlayCountStats(mediaPlayCountPayload));
  }, [playedSongCategoryFilter, playedSongDateFilter]);

  useEffect(() => {
    const userLocationPayload = {
      start_date: userLocationDateFilter.startDate,
      end_date: userLocationDateFilter.endDate,
    };
    dispatch(DashboardAction.getUserLocationStats(userLocationPayload));
  }, [userLocationDateFilter]);

  return (
    <>
      <Row className="flex-row-space-around-start mb-20">
        <Col
          xxl={6}
          xl={7}
          lg={8}
          md={24}
          sm={24}
          xs={24}
          className="flex-column card "
        >
          <Row className="mb-20">
            <Col span={24}>
              <Row justify={"end"}>
                <Col>
                  <Picker.RangeDatePicker
                    size={"large"}
                    defaultValue={[
                      dayjs(cardCountFilter?.startDate, "MM/DD/YYYY"),
                      dayjs(cardCountFilter?.endDate, "MM/DD/YYYY"),
                    ]}
                    format={"MM/DD/YYYY"}
                    allowClear={false}
                    onChange={(e, date) => {
                      setCardCountFilter({
                        startDate: dayjs(date[0]).format("MM/DD/YYYY"),
                        endDate: dayjs(date[1]).format("MM/DD/YYYY"),
                      });
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mb-10">
            <Col className="flex-row-space-center stats-card" span={24}>
              <Text.Basic
                text="Total Users"
                color={Colors.BlackText2}
                fontSize={isLaptop ? 13 : isMobile ? 12 : 11}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
              <Text.Basic
                text={<CountUp end={dashboard?.userCount} />}
                color={Colors.Yellow}
                fontSize={20}
                fontWeight={700}
                fontFamily={Fonts[MontserratBold]}
                textAlign="right"
              />
            </Col>
          </Row>
          <Row className="mb-10">
            <Col className="flex-row-space-center stats-card" span={24}>
              <Text.Basic
                text="Subscribed Users"
                color={Colors.BlackText2}
                fontSize={13}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
              <Text.Basic
                text={<CountUp end={dashboard?.subscriptionCount} />}
                color={Colors.Yellow}
                fontSize={20}
                fontWeight={700}
                fontFamily={Fonts[MontserratBold]}
                textAlign="right"
              />
            </Col>
          </Row>
          <Row className="mb-10">
            <Col className="flex-row-space-center stats-card" span={24}>
              <Text.Basic
                text="Total Media"
                color={Colors.BlackText2}
                fontSize={13}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
              <Text.Basic
                text={<CountUp end={dashboard?.mediaCount} />}
                color={Colors.Yellow}
                fontSize={20}
                fontWeight={700}
                fontFamily={Fonts[MontserratBold]}
                textAlign="right"
              />
            </Col>
          </Row>
          <Row className="mb-10">
            <Col className="flex-row-space-center stats-card" span={24}>
              <Text.Basic
                text="Total Streams"
                color={Colors.BlackText2}
                fontSize={13}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
              <Text.Basic
                text={<CountUp end={dashboard?.streamCount} />}
                color={Colors.Yellow}
                fontSize={20}
                fontWeight={700}
                fontFamily={Fonts[MontserratBold]}
                textAlign="right"
              />
            </Col>
          </Row>
          <Row className="mb-10">
            <Col className="flex-row-space-center stats-card" span={24}>
              <Text.Basic
                text="Audio Purchases"
                color={Colors.BlackText2}
                fontSize={13}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
              <Text.Basic
                text={<CountUp end={dashboard?.audioPurchases} />}
                color={Colors.Yellow}
                fontSize={20}
                fontWeight={700}
                fontFamily={Fonts[MontserratBold]}
                textAlign="right"
              />
            </Col>
          </Row>
          <Row className="mb-10">
            <Col className="flex-row-space-center stats-card" span={24}>
              <Text.Basic
                text="Video Purchases"
                color={Colors.BlackText2}
                fontSize={13}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
              <Text.Basic
                text={<CountUp end={dashboard?.videoPurchases} />}
                color={Colors.Yellow}
                fontSize={20}
                fontWeight={700}
                fontFamily={Fonts[MontserratBold]}
                textAlign="right"
              />
            </Col>
          </Row>
        </Col>
        <Col
          xxl={17}
          xl={16}
          lg={15}
          md={24}
          sm={24}
          xs={24}
          className="flex-column card h-100"
        >
          <Row className="flex-row-space-center mb-10">
            <Col>
              <Text.Basic
                text="Total Registered Users"
                color={Colors.Black}
                fontSize={isMobile ? 16 : 14}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
            </Col>
            <Col xxl={5} xl={8} lg={12} className={!isMobile && "mt-10"}>
              <Picker.RangeDatePicker
                size={"large"}
                defaultValue={[
                  dayjs(signUpFilter?.startDate, "MM/DD/YYYY"),
                  dayjs(signUpFilter?.endDate, "MM/DD/YYYY"),
                ]}
                format={"MM/DD/YYYY"}
                allowClear={false}
                onChange={(e, date) =>
                  handleFilterDateChange(date, "user signup stats")
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Charts.ResponsiveContainer height={388}>
                {dashboard?.userSignupStats
                  ?.map((val) => {
                    return !val.value ? 0 : val.value;
                  })
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.LineChart
                    data={dashboard?.userSignupStats}
                    lineColor={{
                      android: "#FE6779",
                      ios: "#5596F8",
                    }}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                    legendsAlignment={"bottom"}
                    isLegend={false}
                    isTooltip={true}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: Colors.Black,
                    }}
                  >
                    No Data Available for this month range!
                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row justify={"space-around"} className="mb-20">
        <Col
          xxl={6}
          xl={7}
          lg={8}
          md={24}
          sm={24}
          xs={24}
          className="flex-column card h-100"
        >
          <Row>
            <Col className="flex-column">
              <Text.Basic
                text="Media Files Statistics"
                color={Colors.Black}
                fontSize={isMobile ? 16 : 14}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
              <Text.Basic
                text="All media files in Nileur App"
                color={Colors.Black}
                fontSize={isMobile ? 14 : 12}
                fontWeight={300}
                fontFamily={Fonts[MontserratLight]}
                textAlign="left"
              />
              <Picker.RangeDatePicker
                size={"large"}
                className="mt-10"
                defaultValue={[
                  dayjs(mediaDateFilter?.startDate, "MM/DD/YYYY"),
                  dayjs(mediaDateFilter?.endDate, "MM/DD/YYYY"),
                ]}
                allowClear={false}
                format={"MM/DD/YYYY"}
                onChange={(e, date) =>
                  handleFilterDateChange(date, "media types stats")
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Charts.ResponsiveContainer height={250} width={"100%"}>
                {dashboard?.mediaTypeStats
                  ?.map((val) => val.count)
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.PieChart
                    data={dashboard?.mediaTypeStats}
                    lineColor={[Colors.Yellow, Colors.BlueText]}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                    isLegend={false}
                    isTooltip={true}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: Colors.Black,
                    }}
                  >
                    No Data Available for this month range!
                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </Col>
          </Row>
          <Row justify={"center"} className="flex-row-align-center mb-10">
            <span
              style={{
                width: "8px",
                height: "8px",
                background: Colors.Yellow,
                borderRadius: "50px",
                marginInline: "7px",
              }}
            ></span>
            <Text.Basic
              text="Audio"
              color={Colors.Black}
              fontSize={isMobile ? 14 : 12}
              fontWeight={600}
              fontFamily={Fonts[MontserratRegular]}
              textAlign="left"
            />
            <span
              style={{
                width: "8px",
                height: "8px",
                background: Colors.BlueText,
                borderRadius: "50px",
                marginInline: "7px",
              }}
            ></span>
            <Text.Basic
              text="Video"
              color={Colors.Black}
              fontSize={isMobile ? 14 : 12}
              fontWeight={600}
              fontFamily={Fonts[MontserratRegular]}
              textAlign="left"
            />
          </Row>
          <Row className="flex-row">
            <Col>
              <Select.Radio
                onChange={(e) => setMediaCategoryFilter(e.target.value)}
                options={[
                  { label: "Nileur", value: "Nileur" },
                  { label: "Divine Music", value: "Divine-Music" },
                  { label: "Break & Groove", value: "Break-and-Groove" },
                  { label: "Stratting", value: "Stratting" },
                  { label: "Promotion", value: "Promotion" },
                  { label: "Theeacting ", value: "Theeacting " },
                  { label: "Free Music Library", value: "Free-Music-Library" },
                ]}
              />
            </Col>
          </Row>
        </Col>
        <Col
          xxl={17}
          xl={16}
          lg={15}
          md={24}
          sm={24}
          xs={24}
          className="flex-column card h-100"
        >
          <Row className="flex-row-space-center mb-10">
            <Col xxl={11} xl={11} lg={18} md={11} sm={22} xs={24}>
              <div className="flex-row-align-center">
                <Text.Basic
                  text="Most Played Songs"
                  color={Colors.Black}
                  fontSize={isMobile ? 16 : 14}
                  fontWeight={600}
                  fontFamily={Fonts[MontserratMedium]}
                  textAlign="left"
                />
                <span
                  style={{
                    width: "8px",
                    height: "8px",
                    background: Colors.Yellow,
                    borderRadius: "50px",
                    marginInline: "7px",
                  }}
                ></span>
                <Text.Basic
                  text="Audio"
                  color={Colors.Black}
                  fontSize={isMobile ? 13 : 12}
                  fontWeight={600}
                  fontFamily={Fonts[MontserratRegular]}
                  textAlign="left"
                />
                <span
                  style={{
                    width: "8px",
                    height: "8px",
                    background: Colors.BlueText,
                    borderRadius: "50px",
                    marginInline: "7px",
                  }}
                ></span>
                <Text.Basic
                  text="Video"
                  color={Colors.Black}
                  fontSize={isMobile ? 13 : 12}
                  fontWeight={600}
                  fontFamily={Fonts[MontserratRegular]}
                  textAlign="left"
                />
              </div>
            </Col>
            <Col
              xxl={6}
              xl={13}
              lg={24}
              md={13}
              sm={24}
              xs={24}
              className={isLaptop ? "flex-row" : "flex-row mt-10"}
            >
              <Row justify={"space-between"}>
                <Col xxl={7} xl={7} lg={7} md={7} sm={12} xs={12}>
                  <TextField.SelectField
                    inputStyle={{ width: "100%" }}
                    size={"large"}
                    placeholder={"View all"}
                    onChange={handleFilterPlayedSongType}
                    allowClear={false}
                    value={
                      playedSongCategoryFilter === ""
                        ? undefined
                        : playedSongCategoryFilter
                    }
                    options={[
                      {
                        label: "View All",
                        value: "all",
                      },
                      {
                        label: "Audio",
                        value: "audio",
                      },
                      {
                        label: "Video",
                        value: "video",
                      },
                    ]}
                  />
                </Col>
                <Col
                  xxl={16}
                  xl={16}
                  lg={16}
                  md={16}
                  sm={24}
                  xs={24}
                  className={!isMobile && "mt-10"}
                >
                  <Picker.RangeDatePicker
                    allowClear={false}
                    defaultValue={[
                      dayjs(playedSongDateFilter?.startDate, "MM/DD/YYYY"),
                      dayjs(playedSongDateFilter?.endDate, "MM/DD/YYYY"),
                    ]}
                    format={"MM/DD/YYYY"}
                    onChange={(e, date) =>
                      handleFilterDateChange(date, "played song stats")
                    }
                    size={"large"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Charts.ResponsiveContainer height={isLaptop ? 395 : 435}>
                {dashboard?.mediaPlayCountStats
                  ?.map((val) => {
                    return !val.play_count ? 0 : val.play_count;
                  })
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.BarChart
                    data={
                      playedSongCategoryFilter
                        ? playedSongData
                        : dashboard?.mediaPlayCountStats
                    }
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                    // legendsAlignment={"bottom"}
                    // isLegend={false}
                    isLocation={false}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: Colors.Black,
                    }}
                  >
                    No Data Available for this month range!
                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-20">
        <Col span={24} className="flex-column card h-100">
          <Row className="flex-row-space-center mb-10">
            <Col>
              <Text.Basic
                text="Most User in Location"
                color={Colors.Black}
                fontSize={isMobile ? 16 : 14}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
              />
            </Col>
            <Col className={!isMobile && "mt-10"}>
              <Picker.RangeDatePicker
                size={"large"}
                defaultValue={[
                  dayjs(userLocationDateFilter?.startDate, "MM/DD/YYYY"),
                  dayjs(userLocationDateFilter?.endDate, "MM/DD/YYYY"),
                ]}
                format={"MM/DD/YYYY"}
                allowClear={false}
                onChange={(e, date) =>
                  handleFilterDateChange(date, "user location stats")
                }
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Charts.ResponsiveContainer height={395}>
                {dashboard?.userLocationStats
                  ?.map((val) => {
                    return !val.value ? 0 : val.value;
                  })
                  .reduce((total, value) => total + value, 0) > 0 ? (
                  <Charts.BarChart
                    data={dashboard?.userLocationStats}
                    margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                    isLocation={true}
                    // legendsAlignment={"bottom"}
                    // isLegend={false}
                  />
                ) : (
                  <h4
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: Colors.Black,
                    }}
                  >
                    No Data Available for this month range!
                  </h4>
                )}
              </Charts.ResponsiveContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-20" justify={"flex-start"}>
        <Col className="flex-column">
          <Text.Basic
            text="Popular Songs"
            color={Colors.Black}
            fontSize={isMobile ? 16 : 14}
            fontWeight={600}
            fontFamily={Fonts[MontserratMedium]}
            textAlign="left"
          />
          <Text.Basic
            text="Most popular song among Nileur app users"
            color={Colors.Black}
            fontSize={isMobile ? 14 : 12}
            fontWeight={300}
            fontFamily={Fonts[MontserratLight]}
            textAlign="left"
          />
        </Col>
      </Row>
      {mediaListing?.isLoading ? (
        <Row
          justify={"space-around"}
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          {Array.from(
            [1, 2, 3, 4, 5].map((item) => (
              <Col xxl={3} xl={4} lg={4} md={7} sm={6} xs={7} className="w-100">
                <Skeleton.Image active={true} className="mb-10" />
                <Skeleton
                  paragraph={{
                    rows: 1,
                  }}
                  active={true}
                />
              </Col>
            ))
          )}
        </Row>
      ) : (
        <Row
          style={{
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
          }}
        >
          {mediaListing?.mediaList?.map((item) => (
            <Col
              xxl={3}
              xl={4}
              lg={4}
              md={4}
              sm={6}
              xs={7}
              className={styles.songCard}
              onClick={() =>
                window.open(
                  `https://nileurdata.s3.eu-west-2.amazonaws.com/${item?.permalink}`,
                  "_blank"
                )
              }
            >
              <img
                src={`https://nileurdata.s3.eu-west-2.amazonaws.com/${item?.thumbnail}`}
                style={{ borderRadius: "2px", width: "130px", height: "130px" }}
                className="mb-5"
              />
              <Text.Basic
                title={item?.title}
                text={
                  item?.title?.length > 10
                    ? `${item?.title?.slice(0, 10)}...`
                    : item?.title
                }
                color={Colors.Black}
                fontSize={isMobile ? 16 : 14}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
                textAlign="left"
                className="ml-10 mb-5"
              />
              <Text.Basic
                title={item?.title}
                text={
                  item?.artist?.length > 20
                    ? `${item?.artist?.slice(0, 20)}...`
                    : item?.artist
                }
                color={Colors.Black}
                fontSize={isMobile ? 14 : 12}
                fontWeight={300}
                fontFamily={Fonts[MontserratLight]}
                textAlign="left"
                className="ml-10"
              />
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

export default Dashboard;
