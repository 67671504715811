import {
  CHANGE_USER_STATUS,
  CHANGE_USER_STATUS_FAILURE,
  CHANGE_USER_STATUS_SUCCESS,
  GET_USER_LISTING,
  GET_USER_LISTING_FAILURE,
  GET_USER_LISTING_SUCCESS,
  GET_USER_LOCATIONS,
  GET_USER_LOCATIONS_FAILURE,
  GET_USER_LOCATIONS_SUCCESS,
} from "../constants";

export default class UserAction {
  static getUserListing(payload, cb) {
    return {
      type: GET_USER_LISTING,
      payload,
      cb,
    };
  }
  static getUserListingSuccess(payload) {
    return {
      type: GET_USER_LISTING_SUCCESS,
      payload,
    };
  }
  static getUserListingFailure() {
    return {
      type: GET_USER_LISTING_FAILURE,
    };
  }

  static changeUserStatus(id, cb) {
    return {
      type: CHANGE_USER_STATUS,
      id,
      cb,
    };
  }
  static changeUserStatusSuccess(payload) {
    return {
      type: CHANGE_USER_STATUS_SUCCESS,
      payload,
    };
  }
  static changeUserStatusFailure() {
    return {
      type: CHANGE_USER_STATUS_FAILURE,
    };
  }

  static getUserLocations(keyword, cb) {
    return {
      type: GET_USER_LOCATIONS,
      keyword,
      cb,
    };
  }
  static getUserLocationsSuccess(payload) {
    return {
      type: GET_USER_LOCATIONS_SUCCESS,
      payload,
    };
  }
  static getUserLocationsFailure() {
    return {
      type: GET_USER_LOCATIONS_FAILURE,
    };
  }
}
