export const Montserrat = "Montserrat";
export const Poppins = "Poppins";
export const MontserratBlack = "Montserrat-Black";
export const MontserratBlackItalic = "Montserrat-BlackItalic";
export const MontserratBold = "Montserrat-Bold";
export const MontserratBoldItalic = "Montserrat-BoldItalic";
export const MontserratExtraBold = "Montserrat-ExtraBold";
export const MontserratExtraBoldItalic = "Montserrat-ExtraBoldItalic";
export const MontserratExtraLight = "Montserrat-ExtraLight";
export const MontserratExtraLightItalic = "Montserrat-ExtraLightItalic";
export const MontserratItalic = "Montserrat-Italic";
export const MontserratLight = "Montserrat-Light";
export const MontserratLightItalic = "Montserrat-LightItalic";
export const MontserratMedium = "Montserrat-Medium";
export const MontserratMediumItalic = "Montserrat-MediumItalic";
export const MontserratRegular = "Montserrat-Regular";
export const MontserratSemiBold = "Montserrat-SemiBold";
export const MontserratSemiBoldItalic = "Montserrat-SemiBoldItalic";
export const MontserratThin = "Montserrat-Thin";
export const MontserratThinItalic = "Montserrat-ThinItalic";
export const PoppinsBlack = "Poppins-Black";
export const PoppinsBlackItalic = "Poppins-BlackItalic";
export const PoppinsBold = "Poppins-Bold";
export const PoppinsBoldItalic = "Poppins-BoldItalic";
export const PoppinsExtraBold = "Poppins-ExtraBold";
export const PoppinsExtraBoldItalic = "Poppins-ExtraBoldItalic";
export const PoppinsExtraLight = "Poppins-ExtraLight";
export const PoppinsExtraLightItalic = "Poppins-ExtraLightItalic";
export const PoppinsItalic = "Poppins-Italic";
export const PoppinsLight = "Poppins-Light";
export const PoppinsLightItalic = "Poppins-LightItalic";
export const PoppinsMedium = "Poppins-Medium";
export const PoppinsMediumItalic = "Poppins-MediumItalic";
export const PoppinsRegular = "Poppins-Regular";
export const PoppinsSemiBold = "Poppins-SemiBold";
export const PoppinsSemiBoldItalic = "Poppins-SemiBoldItalic";
export const PoppinsThin = "Poppins-Thin";
export const PoppinsThinItalic = "Poppins-ThinItalic";

const Fonts = {
  [Montserrat]: "Montserrat",
  [Poppins]: "Poppins",
  [MontserratBlack]: "Montserrat, Black",
  [MontserratBlackItalic]: "Montserrat, BlackItalic",
  [MontserratBold]: "Montserrat, Bold",
  [MontserratBoldItalic]: "Montserrat, BoldItalic",
  [MontserratExtraBold]: "Montserrat, ExtraBold",
  [MontserratExtraBoldItalic]: "Montserrat, ExtraBoldItalic",
  [MontserratExtraLight]: "Montserrat, ExtraLight",
  [MontserratExtraLightItalic]: "Montserrat, ExtraLightItalic",
  [MontserratItalic]: "Montserrat, Italic",
  [MontserratLight]: "Montserrat, Light",
  [MontserratLightItalic]: "Montserrat, LightItalic",
  [MontserratMedium]: "Montserrat, Medium",
  [MontserratMediumItalic]: "Montserrat, MediumItalic",
  [MontserratRegular]: "Montserrat, Regular",
  [MontserratSemiBold]: "Montserrat, SemiBold",
  [MontserratSemiBoldItalic]: "Montserrat, SemiBoldItalic",
  [MontserratThin]: "Montserrat, Thin",
  [MontserratThinItalic]: "Montserrat, ThinItalic",
  [PoppinsBlack]: "Poppins, Black",
  [PoppinsBlackItalic]: "Poppins, BlackItalic",
  [PoppinsBold]: "Poppins, Bold",
  [PoppinsBoldItalic]: "Poppins, BoldItalic",
  [PoppinsExtraBold]: "Poppins, ExtraBold",
  [PoppinsExtraBoldItalic]: "Poppins, ExtraBoldItalic",
  [PoppinsExtraLight]: "Poppins, ExtraLight",
  [PoppinsExtraLightItalic]: "Poppins, ExtraLightItalic",
  [PoppinsItalic]: "Poppins, Italic",
  [PoppinsLight]: "Poppins, Light",
  [PoppinsLightItalic]: "Poppins, LightItalic",
  [PoppinsMedium]: "Poppins, Medium",
  [PoppinsMediumItalic]: "Poppins, MediumItalic",
  [PoppinsRegular]: "Poppins, Regular",
  [PoppinsSemiBold]: "Poppins, SemiBold",
  [PoppinsSemiBoldItalic]: "Poppins, SemiBoldItalic",
  [PoppinsThin]: "Poppins, Thin",
  [PoppinsThinItalic]: "Poppins, ThinItalic",
};

export default Fonts;
