import { delay, put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { AuthAction } from "../actions";

export default class AuthMiddleware {
  static *SignIn({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/admin/login", payload);
      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.message });
        localStorage.setItem("authUser", JSON.stringify(res.data.data));
        if (cb) {
          cb(true);
        }
        yield put(AuthAction.SignInSuccess(res.data.data));
      } else {
        yield put(AuthAction.SignInFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.SignInFailure());
      Utils.showSnackBar({ message: "Login failed", severity: "error" });
    }
  }

  static *Logout({ cb }) {
    try {
      yield delay(1000);
      localStorage.removeItem("authUser");
      yield put(AuthAction.LogoutSuccess());
      Utils.showSnackBar({ message: "Successfully logout" });
      if (cb) {
        cb();
      }
    } catch (err) {
      yield put(AuthAction.LogoutFailure());
      Utils.showSnackBar({ message: "Logout failed", severity: "error" });
    }
  }

  static *ForgotPassword({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/password/otp/send", payload);
      if (res.status == 200) {
        yield put(AuthAction.ForgotPasswordSuccess(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
        cb();
      } else {
        yield put(AuthAction.ForgotPasswordFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.ForgotPasswordFailure(err));
      Utils.showSnackBar({
        message: "Forgot password failed",
        severity: "error",
      });
    }
  }

  static *VerifyOTP({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/password/otp/verify", payload);
      if (res.status == 200) {
        yield put(AuthAction.verifyCodeSuccess(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
        cb();
      } else {
        yield put(AuthAction.verifyCodeFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
        // yield delay(3000);
        // yield put(AuthAction.removeError());
      }
    } catch (err) {
      yield put(AuthAction.verifyCodeFailure(err));
      Utils.showSnackBar({ message: "OTP failed", severity: "error" });
      //   yield delay(3000);
      //   yield put(AuthAction.removeError());
    }
  }

  static *ResetPassword({ payload, cb }) {
    try {
      let res = yield ApiCaller.Post("/password/otp/reset", payload);
      if (res.status == 200) {
        yield put(AuthAction.resetPasswordSuccess(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
        cb();
      } else {
        yield put(AuthAction.resetPasswordFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.resetPasswordFailure(err));
      Utils.showSnackBar({
        message: "Reset password failed",
        severity: "error",
      });
    }
  }

  static *ChangePassword({ payload, cb }) {
    try {
      let res = yield ApiCaller.Put("/profile/password/update", payload);
      if (res.status == 201) {
        yield put(AuthAction.changePasswordSuccess(res.data.data));
        Utils.showSnackBar({ message: res.data.message });
        cb();
      } else {
        yield put(AuthAction.changePasswordFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.changePasswordFailure(err));
      Utils.showSnackBar({
        message: "Change password failed",
        severity: "error",
      });
    }
  }
}
