import { Modal } from "antd";
import React from "react";

const Basic = ({
  title,
  open,
  handleCancel,
  style,
  children,
  centered,
  closable,
  className,
  ...props
}) => {
  return (
    <Modal
      className={className ? className : "ant-modal"}
      title={title}
      style={style}
      centered={centered}
      open={open}
      closable={closable}
      onCancel={handleCancel}
      footer={null}
      {...props}
    >
      {children}
    </Modal>
  );
};

export default Basic;
