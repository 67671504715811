import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import ReactCodeInput from "react-code-input";

import { Button, Text } from "../../components";
import { Colors, Utils } from "../../config";
import LoginBox from "../../components/Layout/LoginBox";

import Fonts, {
  MontserratLight,
  MontserratMedium,
  MontserratRegular,
  MontserratSemiBold,
  PoppinsSemiBold,
} from "../../config/fonts";
import { AuthAction } from "../../store/actions";
import styles from "./styles";

function VerifyOTP() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const [counter, setCounter] = useState(35);
  const isLoading = useSelector((state) => state.auth.verifyLoader);
  const [state, setState] = useState({
    code: "",
    codeError: "",
  });

  const verifyOTP = () => {
    if (state.code.length === 0) {
      setState({
        ...state,
        codeError: "Please enter code",
      });
    } else if (state.code.length < 4 && state.code.length > 0) {
      setState({
        ...state,
        codeError: "Incomplete Code",
      });
    } else {
      setState({
        ...state,
        codeError: "",
      });
      const payload = {
        email: "admin_nileur@yopmail.com",
        verification_code: state.code,
      };
      dispatch(
        AuthAction.verifyCode(payload, () =>
          navigate("/reset-password", {
            state: {
              otpCode: state.code,
            },
          })
        )
      );
    }
  };

  const handleChange = (val) => {
    setState({
      code: val,
      codeError: "",
    });
  };

  const handleResendOTP = () => {
    const payload = {
      email: "admin_nileur@yopmail.com",
      verification_purpose: "Account Recovery",
    };
    dispatch(
      AuthAction.ForgotPassword(payload, () => {
        Utils.showSnackBar({
          message: "OTP sent to your email",
        });
        setCounter(35);
      })
    );
  };

  useEffect(() => {
    const timer =
      counter > 0 &&
      setInterval(() => {
        setCounter(counter - 1);
      }, 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <LoginBox size="small">
      <Col lg={24} md={24} sm={24} xs={24} style={styles.flex}>
        <Text.Basic
          text="OTP Verification"
          fontWeight={"bold"}
          fontSize={isMobile ? 36 : isSmallMobile ? 28 : 24}
          textAlign="center"
          fontFamily={Fonts[MontserratMedium]}
          color={Colors.Black}
          className="mb-5"
        />
        <Text.Basic
          text="We sent otp verification to your email"
          fontSize={isMobile ? 16 : isSmallMobile ? 14 : 13}
          textAlign="center"
          fontFamily={Fonts[MontserratLight]}
          color={Colors.Black}
        />
      </Col>
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        className={!isMobile && "my-20"}
      >
        <ReactCodeInput
          type="number"
          inputStyle={{
            textAlign: "center",
            fontFamily: Fonts[MontserratRegular],
            fontWeight: "bold",
            margin: "5px",
            width: "60px",
            height: "60px",
            MozAppearance: "textfield",
            borderRadius: "10px",
            fontSize: "30px",
            backgroundColor: "white",
            color: Colors.TextColor,
            border: "none",
          }}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </Col>
      {state.codeError && (
        <Col
          span={24}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Text.Error
            style={{ width: "100%" }}
            textAlign={"center"}
            text={state.codeError}
          />
        </Col>
      )}

      <Col span={24}>
        <Row justify={"center"}>
          <Col span={24}>
            <Button.Basic
              text="Continue"
              size={isMobile ? "large" : "medium"}
              onClick={verifyOTP}
              className="mb-20"
              loading={isLoading}
              disabled={isLoading}
              buttonStyle={{
                width: "100%",
                height: "45px",
                backgroundColor: "#7836FF",
                color: "white",
                fontSize: "20px",
                fontFamily: Fonts[PoppinsSemiBold],
                borderRadius: "8px",
              }}
            />
          </Col>
          <Col>
            {counter > 0 ? (
              <div className="center">
                <Text.Basic
                  text={"Resend OTP in "}
                  fontSize={16}
                  textAlign="center"
                  fontFamily={Fonts[MontserratLight]}
                  color={Colors.Black}
                  className="mr-5"
                />
                <span
                  style={{
                    fontSize: "16px",
                    color: "#7836FF",
                    fontWeight: "500",
                  }}
                >
                  {"  "}0:{counter < 10 ? `0${counter}` : counter}
                </span>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "auto",
                }}
                onClick={handleResendOTP}
              >
                <Text.Basic
                  text={"Didn't receive any code?"}
                  fontSize={isMobile ? 14 : 13}
                  textAlign="center"
                  fontFamily={Fonts[MontserratRegular]}
                  color={Colors.Black}
                  className="mr-5"
                />
                <span
                  style={{
                    fontSize: `${isMobile ? "16px" : "14px"}`,
                    fontWeight: "600",
                    fontFamily: Fonts[MontserratSemiBold],
                    color: "black",
                    cursor: "pointer",
                  }}
                >
                  {"  "}Resend OTP
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </LoginBox>
  );
}

export default VerifyOTP;
