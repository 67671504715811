import { Navigate } from "react-router";

import {
  AddSong,
  ChangePassword,
  Dashboard,
  MediaFeedback,
  PromoCode,
  ResetPassword,
  SignIn,
  SongFile,
  UserManagement,
  VerifyOTP,
} from "../../containers";

export const routes = {
  protected: {
    dashboard: "/dashboard",
    users: "/users",
    songFile: "/song-file",
    addSong: "/add-song",
    changePassword: "/change-password",
    mediaFeedback: "/media-feedback",
    promoCodes: "/promo-codes",
  },
  public: {
    login: "/login",
    verifyOTP: "/verify-otp",
    resetPassword: "/reset-password",
  },
};

export const PROTECTED_ROUTES = [
  {
    path: routes.protected.dashboard,
    exact: false,
    component: <Dashboard />,
  },
  {
    path: routes.protected.users,
    exact: false,
    component: <UserManagement />,
  },
  {
    path: routes.protected.songFile,
    exact: false,
    component: <SongFile />,
  },
  {
    path: routes.protected.mediaFeedback,
    exact: false,
    component: <MediaFeedback />,
  },
  {
    path: routes.protected.changePassword,
    exact: false,
    component: <ChangePassword />,
  },
  {
    path: routes.protected.addSong,
    exact: false,
    component: <AddSong />,
  },
  {
    path: routes.protected.promoCodes,
    exact: false,
    component: <PromoCode />,
  },
  {
    path: "*",
    exact: true,
    component: <Navigate to={routes.protected.dashboard} replace={true} />,
  },
];

export const PUBLIC_ROUTES = [
  {
    path: routes.public.login,
    exact: false,
    component: <SignIn />,
  },
  {
    path: routes.public.verifyOTP,
    exact: false,
    component: <VerifyOTP />,
  },
  {
    path: routes.public.resetPassword,
    exact: false,
    component: <ResetPassword />,
  },
  {
    path: "*",
    exact: true,
    component: <Navigate to={routes.public.login} replace={true} />,
  },
];
