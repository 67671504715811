import { all, takeLatest } from "redux-saga/effects";

import {
  ADD_MEDIA,
  ADD_PROMO_CODE,
  CHANGE_PASSWORD,
  CHANGE_PROMO_CODE_STATUS,
  CHANGE_USER_STATUS,
  DELETE_FEEDBACK,
  DELETE_MEDIA,
  DELETE_MEDIA_LYRICS,
  DELETE_PROMO_CODE,
  EDIT_MEDIA,
  FORGOT_PASSWORD,
  GET_AUDIO_PURCHASES,
  GET_FEEDBACKS,
  GET_MEDIA_COUNT,
  GET_MEDIA_LISTING,
  GET_MEDIA_PLAY_COUNT_STATS,
  GET_MEDIA_TYPE_STATS,
  GET_PROMO_CODES,
  GET_STREAMS_COUNT,
  GET_SUBSCRIPTIONS_COUNT,
  GET_USERS_COUNT,
  GET_USER_LISTING,
  GET_USER_LOCATIONS,
  GET_USER_LOCATION_STATS,
  GET_USER_SIGNUP_STATS,
  GET_VIDEO_PURCHASES,
  LOGOUT,
  REPLY_FEEDBACK,
  RESET_PASSWORD,
  SIGN_IN,
  VERIFY_OTP,
} from "../constants";
import {
  AuthMiddleware,
  DashboardMiddleware,
  FeedbackMiddleware,
  MediaMiddleware,
  PromoCodeMiddleware,
  UserMiddleware,
} from "../middlewares";

export function* Sagas() {
  yield all([
    //auth
    yield takeLatest(SIGN_IN, AuthMiddleware.SignIn),
    yield takeLatest(LOGOUT, AuthMiddleware.Logout),
    yield takeLatest(FORGOT_PASSWORD, AuthMiddleware.ForgotPassword),
    yield takeLatest(VERIFY_OTP, AuthMiddleware.VerifyOTP),
    yield takeLatest(RESET_PASSWORD, AuthMiddleware.ResetPassword),
    yield takeLatest(CHANGE_PASSWORD, AuthMiddleware.ChangePassword),

    //media
    yield takeLatest(GET_MEDIA_LISTING, MediaMiddleware.GetMediaListing),
    yield takeLatest(DELETE_MEDIA, MediaMiddleware.DeleteMedia),
    yield takeLatest(ADD_MEDIA, MediaMiddleware.AddMedia),
    yield takeLatest(EDIT_MEDIA, MediaMiddleware.EditMedia),
    yield takeLatest(DELETE_MEDIA_LYRICS, MediaMiddleware.DeleteMediaLyrics),

    //user management
    yield takeLatest(GET_USER_LISTING, UserMiddleware.GetUserListing),
    yield takeLatest(GET_USER_LOCATIONS, UserMiddleware.GetUserLocations),
    yield takeLatest(CHANGE_USER_STATUS, UserMiddleware.ChangeUserStatus),

    //dashboard
    yield takeLatest(GET_USERS_COUNT, DashboardMiddleware.GetUsersCount),
    yield takeLatest(
      GET_SUBSCRIPTIONS_COUNT,
      DashboardMiddleware.GetSubscriptionsCount
    ),
    yield takeLatest(GET_MEDIA_COUNT, DashboardMiddleware.GetMediaCount),
    yield takeLatest(GET_STREAMS_COUNT, DashboardMiddleware.GetStreamsCount),
    yield takeLatest(
      GET_AUDIO_PURCHASES,
      DashboardMiddleware.GetAudioPurchases
    ),
    yield takeLatest(
      GET_VIDEO_PURCHASES,
      DashboardMiddleware.GetVideoPurchases
    ),
    yield takeLatest(
      GET_USER_SIGNUP_STATS,
      DashboardMiddleware.GetUserSignupStats
    ),
    yield takeLatest(
      GET_MEDIA_PLAY_COUNT_STATS,
      DashboardMiddleware.GetMediaPlayCountStats
    ),
    yield takeLatest(
      GET_MEDIA_TYPE_STATS,
      DashboardMiddleware.GetMediaTypeStats
    ),
    yield takeLatest(
      GET_USER_LOCATION_STATS,
      DashboardMiddleware.GetUserLocationStats
    ),

    //media feedback
    yield takeLatest(GET_FEEDBACKS, FeedbackMiddleware.GetFeedbacks),
    yield takeLatest(DELETE_FEEDBACK, FeedbackMiddleware.DeleteFeedback),
    yield takeLatest(REPLY_FEEDBACK, FeedbackMiddleware.ReplyFeedback),

    //promo code
    yield takeLatest(GET_PROMO_CODES, PromoCodeMiddleware.GetPromoCodes),
    yield takeLatest(DELETE_PROMO_CODE, PromoCodeMiddleware.DeletePromoCode),
    yield takeLatest(ADD_PROMO_CODE, PromoCodeMiddleware.AddPromoCode),
    yield takeLatest(
      CHANGE_PROMO_CODE_STATUS,
      PromoCodeMiddleware.ChangePromoCodeStatus
    ),
  ]);
}
