import { Col, Popconfirm, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import { useNavigate } from "react-router";
import * as Icon from "react-icons/all";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { Button, Pagination, Table, Text, TextField } from "../../components";
import { Colors, Images } from "../../config";

import Fonts, {
  MontserratBold,
  MontserratLight,
  MontserratRegular,
} from "../../config/fonts";
import { MediaAction } from "../../store/actions";

function SongFile() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isTablet = useMedia({ minWidth: "770px" });
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const mediaListing = useSelector((state) => state.media);
  const [page, setPage] = useState(1);
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    sort_by: "date",
    order: "desc",
    media_type: "",
    media_category: "",
    title: "",
  });

  const handlePagination = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  const handleChangeSorting = (value) => {
    if (value === "all") {
      setState({
        ...state,
        sort_by: "date",
        order: "desc",
      });
    } else {
      setState({
        ...state,
        sort_by: "play_count",
        order: value,
      });
    }
    dispatch(MediaAction.getMediaListing(state));
  };

  const handleDeleteSong = (id) => {
    dispatch(
      MediaAction.deleteMedia(id, () => {
        dispatch(MediaAction.getMediaListing(state));
      })
    );
  };

  useEffect(() => {
    dispatch(MediaAction.getMediaListing(state));
  }, [state, page]);

  return (
    <div className="wrapper">
      <Row className="flex-row-space-center mb-20 mx-15">
        <Col
          className="flex-column"
          xxl={6}
          xl={8}
          lg={6}
          md={24}
          sm={24}
          xs={24}
        >
          <div className="flex-row-align-center">
            <Text.Basic
              text="Song File"
              color={Colors.Black}
              fontSize={isMobile ? 25 : isSmallMobile ? 20 : 18}
              fontWeight={600}
              fontFamily={Fonts[MontserratBold]}
              textAlign="left"
            />
            <span
              style={{
                width: "8px",
                height: "8px",
                background: "#7836FF",
                borderRadius: "50px",
                marginInline: "10px",
              }}
            ></span>
            <Text.Basic
              text={mediaListing?.count}
              color={Colors.Black}
              fontSize={18}
              fontWeight={400}
              fontFamily={Fonts[MontserratRegular]}
              textAlign="left"
            />
          </div>
          <Text.Basic
            text="These are the songs currently uploaded"
            color={Colors.LightGray}
            fontSize={isMobile ? 16 : isSmallMobile ? 14 : 12}
            fontWeight={300}
            fontFamily={Fonts[MontserratLight]}
            textAlign="left"
          />
        </Col>
        <Col
          xxl={8}
          xl={15}
          lg={18}
          md={24}
          sm={24}
          xs={24}
          className={!isTablet && "mt-10"}
        >
          <Row className="flex-row-space-between">
            <Col
              xxl={6}
              xl={6}
              md={6}
              sm={24}
              xs={24}
              className={!isMobile && "mt-10"}
            >
              <TextField.SelectField
                inputStyle={{ width: "100%" }}
                size={"large"}
                className="listing-filter"
                placeholder={"Most Played"}
                value={state.sort_by === "date" ? undefined : state.order}
                allowClear={false}
                onChange={handleChangeSorting}
                options={[
                  {
                    label: "Select all by date",
                    value: "all",
                  },
                  {
                    label: "Select ascending",
                    value: "asc",
                  },
                  {
                    label: "Select descending",
                    value: "desc",
                  },
                ]}
              />
            </Col>
            <Col
              xxl={6}
              xl={6}
              md={6}
              sm={24}
              xs={24}
              className={!isMobile && "mt-10"}
            >
              <TextField.SelectField
                inputStyle={{ width: "100%" }}
                size={"large"}
                className="listing-filter"
                placeholder={"Select Type"}
                allowClear={false}
                onChange={(value) => {
                  if (value === "all") {
                    setState({
                      ...state,
                      media_type: "",
                    });
                  } else {
                    setState({
                      ...state,
                      media_type: value,
                    });
                  }
                }}
                value={state.media_type === "" ? undefined : state.media_type}
                options={[
                  {
                    label: "View All",
                    value: "all",
                  },
                  {
                    label: "Select Audio file",
                    value: "audio",
                  },
                  {
                    label: "Select Video file",
                    value: "video",
                  },
                ]}
              />
            </Col>
            <Col
              xxl={6}
              xl={6}
              md={6}
              sm={24}
              xs={24}
              className={!isMobile && "mt-10"}
            >
              <TextField.SelectField
                inputStyle={{ width: "100%" }}
                size={"large"}
                className="listing-filter"
                placeholder={"Select Category"}
                allowClear={false}
                onChange={(value) => {
                  if (value === "all") {
                    setState({
                      ...state,
                      media_category: "",
                    });
                  } else {
                    setState({
                      ...state,
                      media_category: value,
                    });
                  }
                }}
                value={
                  state.media_category === "" ? undefined : state.media_category
                }
                options={[
                  {
                    label: "View All",
                    value: "all",
                  },
                  {
                    label: "Promotion",
                    value: "Promotion",
                  },
                  {
                    label: "Nileur",
                    value: "Nileur",
                  },
                  {
                    label: "Divine Music",
                    value: "Divine-Music",
                  },
                  {
                    label: "Break & Groove",
                    value: "Break-and-Groove",
                  },
                  {
                    label: "Stratting",
                    value: "Stratting",
                  },
                  {
                    label: "Theeacting ",
                    value: "Theeacting ",
                  },
                  {
                    label: "Free Music Library",
                    value: "Free-Music-Library",
                  },
                ]}
              />
            </Col>
            <Col
              xxl={5}
              xl={5}
              md={5}
              sm={24}
              xs={24}
              className={!isMobile && "mt-10"}
            >
              <Button.Basic
                icon={<img src={Images.MusicIcon} className="mr-5" />}
                size={"large"}
                buttonStyle={{
                  backgroundColor: Colors.BlueText,
                  borderRadius: "5px",
                  boxShadow: "0px 3px 6px #7836FF66",
                  color: Colors.White,
                  height: "40px",
                }}
                text={"Upload Song"}
                onClick={() => navigate("/add-song")}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table.Basic
        bordered={false}
        columns={[
          {
            title: "",
            dataIndex: "",
            key: "",
            render: (text, record) => (
              <span>
                <img
                  width={40}
                  src={
                    record?.thumbnail
                      ? `https://nileurdata.s3.eu-west-2.amazonaws.com/${record?.thumbnail}`
                      : Images.SongPlaceholder
                  }
                  alt="song"
                />
              </span>
            ),
          },
          {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (text, record) => (
              <span
                className="ml-10 table-cell"
                title={text}
                onClick={() =>
                  window.open(
                    `https://nileurdata.s3.eu-west-2.amazonaws.com/${record?.permalink}`,
                    "_blank"
                  )
                }
              >
                {text.length > 12 ? `${text.slice(0, 12)}...` : text}
              </span>
            ),
          },
          {
            title: "Upload Date",
            dataIndex: "upload_date",
            key: "upload_date",
            render: (text, record) => (
              <span
                className="table-cell"
                onClick={() =>
                  window.open(
                    `https://nileurdata.s3.eu-west-2.amazonaws.com/${record?.permalink}`,
                    "_blank"
                  )
                }
              >
                {moment(text).format("DD MMM, YYYY")}
              </span>
            ),
          },
          {
            title: "Category",
            dataIndex: "category",
            key: "category",
            render: (text, record) => (
              <span
                className="table-cell"
                onClick={() =>
                  window.open(
                    `https://nileurdata.s3.eu-west-2.amazonaws.com/${record?.permalink}`,
                    "_blank"
                  )
                }
              >
                {text === "Break and Groove" ? "Break & Groove" : text}
              </span>
            ),
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (text, record) => (
              <span
                className="table-cell"
                style={{
                  textTransform: "capitalize",
                  color: text === "video" ? Colors.BlueText : Colors.Yellow,
                }}
                onClick={() =>
                  window.open(
                    `https://nileurdata.s3.eu-west-2.amazonaws.com/${record?.permalink}`,
                    "_blank"
                  )
                }
              >
                {text}
              </span>
            ),
          },
          {
            title: "No. of Downloads",
            dataIndex: "purchases_count",
            key: "purchases_count",
            align: "center",
            render: (text, record) => (
              <span
                className="table-cell"
                onClick={() =>
                  window.open(
                    `https://nileurdata.s3.eu-west-2.amazonaws.com/${record?.permalink}`,
                    "_blank"
                  )
                }
              >
                {text}
              </span>
            ),
          },
          {
            title: "No. of Plays",
            dataIndex: "play_count",
            key: "play_count",
            align: "center",
            render: (text, record) => (
              <span
                className="table-cell"
                onClick={() =>
                  window.open(
                    `https://nileurdata.s3.eu-west-2.amazonaws.com/${record?.permalink}`,
                    "_blank"
                  )
                }
              >
                {text}
              </span>
            ),
          },
          {
            title: "Action",
            dataIndex: "",
            key: "",
            render: (text, record) => (
              <span className="flex-row-align-center">
                <img
                  src={Images.EditIcon}
                  width={30}
                  className="cursor mr-5"
                  onClick={() =>
                    navigate("/add-song", {
                      state: {
                        song: record,
                      },
                    })
                  }
                />
                <Popconfirm
                  title="Delete file"
                  description="Are you sure you want to delete this file?"
                  onConfirm={() => handleDeleteSong(record?.id)}
                  icon={<Icon.CiCircleRemove size={20} color="red" />}
                  placement="topRight"
                  okText="Yes"
                  cancelText="No"
                >
                  <img src={Images.DeleteIcon} width={28} className="cursor" />
                </Popconfirm>
              </span>
            ),
          },
        ]}
        data={mediaListing?.mediaList}
        loading={mediaListing?.isLoading}
      />
      {mediaListing.count > 10 && (
        <Row justify={"center"} className="mt-10">
          <Col>
            <Pagination.Basic
              onChange={handlePagination}
              pageSize={10}
              current={page}
              total={mediaListing.count}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default SongFile;
