import { Col, Popconfirm, Row, Skeleton } from "antd";
import * as Icon from "react-icons/all";
import React, { useEffect, useState } from "react";

import { Button, Modal, Pagination, Text, TextField } from "../../components";
import { Colors, Fonts, Images } from "../../config";

import {
  MontserratBold,
  MontserratLight,
  MontserratMedium,
  MontserratRegular,
  MontserratSemiBold,
  PoppinsMedium,
} from "../../config/fonts";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import { FeedbackAction } from "../../store/actions";
import styles from "./index.module.css";

function MediaFeedback() {
  const dispatch = useDispatch();
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const [page, setPage] = useState(1);
  const feedback = useSelector((state) => state.feedback);
  const [isFeedbackTab, setIsFeedbackTab] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [reply, setReply] = useState("");
  const [replyError, setReplyError] = useState("");
  const [feedbackDetails, setFeedbackDetails] = useState({});
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
  });

  const handleDeleteFeedback = (id) => {
    dispatch(
      FeedbackAction.deleteFeedback(id, () => {
        if (isFeedbackTab) {
          const payload = {
            limit: 10,
            offset: 0,
            type: "General",
          };
          dispatch(FeedbackAction.getFeedbacks(payload));
        } else {
          const payload = {
            limit: 10,
            offset: 0,
            type: "Media",
          };
          dispatch(FeedbackAction.getFeedbacks(payload));
        }
      })
    );
  };

  const handlePagination = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  const handelReplyFeedback = () => {
    if (!reply) {
      setReplyError("Reply cannot be left empty");
    } else if (reply.length < 10) {
      setReplyError("Reply cannot be less than 10 characters");
    } else {
      setReplyError("");
      const payload = {
        feedback_id: feedbackDetails.id,
        message: reply,
      };
      dispatch(
        FeedbackAction.replyFeedback(payload, () => {
          setShowModal(false);
          setReply("");
          if (isFeedbackTab) {
            const payload = {
              limit: 10,
              offset: 0,
              type: "General",
            };
            dispatch(FeedbackAction.getFeedbacks(payload));
          } else {
            const payload = {
              limit: 10,
              offset: 0,
              type: "Media",
            };
            dispatch(FeedbackAction.getFeedbacks(payload));
          }
        })
      );
    }
  };

  useEffect(() => {
    if (isFeedbackTab) {
      const payload = {
        limit: 10,
        offset: 0,
        type: "General",
      };
      dispatch(FeedbackAction.getFeedbacks(payload));
    } else {
      const payload = {
        limit: 10,
        offset: 0,
        type: "Media",
      };
      dispatch(FeedbackAction.getFeedbacks(payload));
    }
  }, [isFeedbackTab, page]);

  return (
    <div className="wrapper">
      <Row className="flex-row-space-center mb-20 mx-15">
        <Col className="flex-column" xl={11} lg={14} md={24} sm={24} xs={24}>
          <Text.Basic
            text={isFeedbackTab ? "Feedback" : "Media Feedback"}
            color={Colors.Black}
            fontSize={isMobile ? 25 : isSmallMobile ? 20 : 18}
            fontWeight={600}
            fontFamily={Fonts[MontserratBold]}
            textAlign="left"
          />
          <Text.Basic
            text={
              isFeedbackTab
                ? "These are the feedbacks currently registered in the app"
                : "These are the media feedbacks currently registered in the app"
            }
            color={Colors.LightGray}
            fontSize={isMobile ? 16 : isSmallMobile ? 14 : 12}
            fontWeight={300}
            fontFamily={Fonts[MontserratLight]}
            textAlign="left"
          />
        </Col>
        <Col
          className="flex-row mb-20 mt-10"
          xl={6}
          lg={9}
          md={24}
          sm={24}
          xs={24}
        >
          <Button.Basic
            size={"large"}
            buttonStyle={{
              backgroundColor: isFeedbackTab
                ? Colors.BlueText
                : Colors.LightBlue,
              borderRadius: "5px 0px 0px 5px",
              color: isFeedbackTab ? Colors.White : Colors.Black,
            }}
            onClick={() => setIsFeedbackTab(true)}
            text={"Feedback"}
          />
          <Button.Basic
            size={"large"}
            buttonStyle={{
              backgroundColor: !isFeedbackTab
                ? Colors.BlueText
                : Colors.LightBlue,
              borderRadius: "0px 5px 5px 0px",
              color: !isFeedbackTab ? Colors.White : Colors.Black,
            }}
            onClick={() => setIsFeedbackTab(false)}
            text={"Media Feedback"}
          />
        </Col>
      </Row>
      <Row justify={"center"} gutter={[20, 10]} className="px-20">
        {feedback.isLoading
          ? Array.from(
              [1, 2, 3].map((item) => (
                <Col xxl={6} xl={8} lg={10} md={16} sm={24} xs={24}>
                  <Row justify={"start"}>
                    <Col className={styles.message} span={24}>
                      <div className={styles.messageHeader}>
                        <Skeleton
                          avatar={{ size: 50 }}
                          paragraph={{ rows: 1 }}
                          active
                          shape="circle"
                        />
                      </div>
                      <Row className="p-15">
                        <Col span={24}>
                          <Skeleton paragraph active />
                        </Col>
                      </Row>
                      <Row className="mt-20">
                        <Col span={12}>
                          <Skeleton.Button block={true} active shape="square" />
                        </Col>
                        <Col span={12}>
                          <Skeleton.Button block={true} active shape="square" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              ))
            )
          : feedback?.feedbacks?.map((item) => (
              <Col xxl={6} xl={8} lg={10} md={16} sm={24} xs={24}>
                <Row justify={"start"}>
                  <Col className={styles.message} span={24}>
                    <Row className={styles.messageHeader}>
                      <Col className={styles.imgContainer}>
                        <img
                          src={Images.DefaultAvatar}
                          width={25}
                          alt="Profile Picture"
                        />
                      </Col>
                      <Col className="ml-10 flex-column">
                        <Text.Basic
                          text={item?.name}
                          color={Colors.Black}
                          fontSize={isMobile ? 16 : isSmallMobile ? 14 : 12}
                          fontWeight={600}
                          fontFamily={Fonts[MontserratBold]}
                          textAlign="left"
                        />
                        <Text.Basic
                          text={item?.email}
                          color={Colors.Black}
                          fontSize={isMobile ? 14 : isSmallMobile ? 12 : 11}
                          fontWeight={400}
                          fontFamily={Fonts[MontserratRegular]}
                          textAlign="left"
                        />
                      </Col>
                    </Row>
                    {item?.type === "Media" && (
                      <Row className="px-15 pt-15">
                        <Col span={24}>
                          <Row>
                            <Col>
                              <Text.Basic
                                text={"Media Title : "}
                                color={Colors.DarkGray2}
                                fontSize={
                                  isMobile ? 16 : isSmallMobile ? 14 : 12
                                }
                                fontWeight={600}
                                fontFamily={Fonts[MontserratSemiBold]}
                                textAlign="left"
                                className="mr-5"
                              />
                            </Col>
                            <Col>
                              <Text.Basic
                                text={item?.media_title}
                                color={Colors.DarkGray2}
                                fontSize={
                                  isMobile ? 16 : isSmallMobile ? 14 : 12
                                }
                                fontWeight={600}
                                fontFamily={Fonts[MontserratSemiBold]}
                                textAlign="left"
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Row className="p-15">
                      <Col span={24} className={styles.messageContent}>
                        <Text.Basic
                          text={item?.message}
                          color={Colors.DarkGray2}
                          fontSize={isMobile ? 13 : 12}
                          fontWeight={500}
                          fontFamily={Fonts[MontserratMedium]}
                          textAlign="left"
                        />
                      </Col>
                    </Row>
                    <Row justify={"end"}>
                      <Col>
                        <Text.Basic
                          text={moment(item?.received_at).format(
                            "DD MMM, YYYY"
                          )}
                          color={Colors.LightGray}
                          fontSize={isMobile ? 14 : isSmallMobile ? 12 : 11}
                          fontWeight={400}
                          fontFamily={Fonts[MontserratRegular]}
                          textAlign="right"
                          className="mr-15"
                        />
                      </Col>
                    </Row>
                    <Row className="mt-20">
                      <Col span={12}>
                        <Popconfirm
                          title="Delete feedback"
                          description="Are you sure you want to delete this feedback?"
                          onConfirm={() => handleDeleteFeedback(item?.id)}
                          icon={<Icon.CiCircleRemove size={20} color="red" />}
                          placement="topLeft"
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button.Basic
                            text="Delete"
                            size={"large"}
                            buttonStyle={{
                              width: "100%",
                              height: "40px",
                              backgroundColor: Colors.Black,
                              color: Colors.White,
                              fontSize: "16px",
                              fontFamily: Fonts[PoppinsMedium],
                              borderRadius: "0px 0px 0px 10px",
                              border: "none",
                            }}
                          />
                        </Popconfirm>
                      </Col>
                      <Col span={12}>
                        <Button.Basic
                          text={!item?.responded ? "Reply" : "Reply Again"}
                          size={"large"}
                          onClick={() => {
                            setShowModal(true);
                            setFeedbackDetails(item);
                          }}
                          buttonStyle={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: Colors.BlueText,
                            color: Colors.White,
                            fontSize: "16px",
                            fontFamily: Fonts[PoppinsMedium],
                            borderRadius: "0px 0px 10px 0px",
                            border: "none",
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            ))}
      </Row>
      {feedback.count > 10 && (
        <Row justify={"center"} className="mt-10">
          <Col>
            <Pagination.Basic
              onChange={handlePagination}
              pageSize={10}
              current={page}
              total={feedback.count}
            />
          </Col>
        </Row>
      )}
      {showModal && (
        <Modal.Basic
          className="modal-lg"
          centered={true}
          title=""
          open={showModal}
          handleCancel={() => {
            setShowModal(false);
            setFeedbackDetails({});
          }}
        >
          <Row>
            <Col>
              <Text.Basic
                color={Colors.Black}
                fontSize={isMobile ? 22 : isSmallMobile ? 20 : 18}
                fontWeight={600}
                fontFamily={Fonts[MontserratBold]}
                text={
                  feedbackDetails?.type === "Feedback Media Reply"
                    ? ""
                    : "Feedback Reply"
                }
                textAlign="left"
              />
            </Col>
          </Row>
          <Row justify="center">
            <Col span={24} className="flex-row-align-center mt-20">
              <Col className={styles.imgContainer}>
                <img
                  src={Images.DefaultAvatar}
                  width={25}
                  alt="Profile Picture"
                />
              </Col>
              <Col>
                <div className="flex-column ml-10">
                  <Text.Basic
                    fontSize={isMobile ? 18 : isSmallMobile ? 16 : 14}
                    color={Colors.Black}
                    fontWeight={600}
                    text={
                      feedbackDetails?.name?.length > 25
                        ? `${feedbackDetails?.name?.slice(0, 25)}...`
                        : feedbackDetails?.name
                    }
                    textAlign="left"
                  />
                  <Text.Basic
                    fontSize={isMobile ? 16 : isSmallMobile ? 14 : 13}
                    color={Colors.Black}
                    fontWeight={500}
                    text={feedbackDetails?.email}
                    textAlign="left"
                  />
                </div>
              </Col>
            </Col>
          </Row>
          {feedbackDetails?.type === "Media" && (
            <Row className="mt-20">
              <Col span={24}>
                <Row>
                  <Col>
                    <Text.Basic
                      text={"Media Title : "}
                      color={Colors.DarkGray2}
                      fontSize={isMobile ? 16 : isSmallMobile ? 14 : 13}
                      fontWeight={600}
                      fontFamily={Fonts[MontserratSemiBold]}
                      textAlign="left"
                      className="mr-5"
                    />
                  </Col>
                  <Col>
                    <Text.Basic
                      text={feedbackDetails?.media_title}
                      color={Colors.DarkGray2}
                      fontSize={isMobile ? 16 : isSmallMobile ? 14 : 13}
                      fontWeight={600}
                      fontFamily={Fonts[MontserratSemiBold]}
                      textAlign="left"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
          <Row className="mt-10">
            <Col span={24}>
              <Row>
                <Col>
                  <Text.Basic
                    text={
                      feedbackDetails?.type === "Feedback Media Message : "
                        ? ""
                        : "Feedback Message : "
                    }
                    color={Colors.DarkGray2}
                    fontSize={isMobile ? 16 : isSmallMobile ? 14 : 13}
                    fontWeight={600}
                    fontFamily={Fonts[MontserratSemiBold]}
                    textAlign="left"
                    className="mr-5"
                  />
                </Col>
                <Col>
                  <Text.Basic
                    fontSize={isMobile ? 16 : isSmallMobile ? 14 : 13}
                    color={Colors.Black}
                    fontWeight={400}
                    text={feedbackDetails?.message}
                    textAlign="left"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-20 modal-textarea">
            <Col span={24}>
              <TextField.Textarea
                value={reply}
                placeholder={"Write your message here"}
                rows={4}
                handleChange={(e) => setReply(e.target.value)}
              />
              <Text.Error text={replyError} />
            </Col>
          </Row>
          <Row className="mt-20 flex-end">
            <Col>
              <Button.Basic
                text="Reply"
                size={"large"}
                onClick={handelReplyFeedback}
                buttonStyle={{
                  width: "100%",
                  height: "40px",
                  backgroundColor: Colors.BlueText,
                  color: Colors.White,
                  fontSize: "16px",
                  fontFamily: Fonts[PoppinsMedium],
                  borderRadius: "10px",
                  border: "none",
                }}
              />
            </Col>
          </Row>
        </Modal.Basic>
      )}
    </div>
  );
}

export default MediaFeedback;
