import propTypes from "prop-types";
import React from "react";

import {
  Brush,
  Legend,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { withTheme } from "styled-components";

const CustomTooltip = ({ payload }) => {
  return (
    <div
      style={{
        borderRadius: 5,
        border: "none",
        padding: 20,
        paddingBottom: 10,
        backgroundColor: "white",
        color: "black",
      }}
    >
      <p className="label mr-b">
        Date:&nbsp;
        <span style={{ wordBreak: "normal" }}>
          {payload[0]?.payload?.details?.name}
        </span>
      </p>
      <p className="label mr-b">
        Value:&nbsp;
        <span style={{ wordBreak: "normal" }}>
          {payload[0]?.payload?.details?.value}
        </span>
      </p>
    </div>
  );
};
class renderLineChart extends React.Component {
  state = {
    updateXAxis: false,
  };

  componentWillUpdate(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ updateXAxis: !this.state.updateXAxis });
    }
  }
  renderLegend = (props) => {
    const { payload } = props;
    const COLORS = ["#6133FF", "#4BE5C0", "#F8604E", "#FF8042"];

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {payload
          .filter((item) => item.dataKey !== "name")
          .map((entry, index) => (
            <>
              <div
                style={{
                  borderRadius: 15,
                  width: 15,
                  height: 15,
                  backgroundColor:
                    entry.value === "UsersUpgrades" ? "#4BE5C0" : COLORS[index],
                  margin: 20,
                }}
              ></div>
              <p>{entry.value}</p>
            </>
            // key={`item-${index}`}>{entry.value}
          ))}
      </div>
    );
  };

  render() {
    var obj;
    if (this.props.data[0]) {
      obj = Object.keys(this.props.data);
    }
    const COLORS = ["#6133FF", "#4BE5C0", "#F8604E", "#FF8042"];
    return (
      <div>
        {/* <LineChart width={this.props.width} height={this.props.height} data={this.props.data.length !== 0 ? this.props.data : [{ empty: 0 }]} margin={this.props.margin}> */}
        <LineChart
          width={this.props.width}
          height={this.props.height}
          data={this.props.data.length !== 0 ? this.props.data : [{ empty: 0 }]}
          margin={this.props.margin}
        >
          <XAxis dataKey="name" />
          <YAxis />

          {/* <Line
                    key={index.toString()}
                    type={this.props.lineType}
                    dataKey={"value"}
                    stroke={"#DDAE38"}
                  /> */}
          {obj && !this.props.loading
            ? obj.map((value, index) => {
                return (
                  <Line
                    // key={index.toString()}
                    type={this.props.lineType}
                    dataKey={"value"}
                    stroke={"#DDAE38"}
                  />
                );
              })
            : null}
          {/* {this.props.isCartReq ? (
            <CartesianGrid
              dataKey={'basicUsers'}
              stroke={this.props.cartStroke}
              strokeDasharray={this.props.cartDash}
            />
          ) : null}
          

          {this.state.updateXAxis ? (
            <XAxis
              key={100}
              dataKey={obj && !this.props.loading ? obj[1] : null}
              allowDuplicatedCategory={false}
            />
          ) : (
            <XAxis
              key={200}
              dataKey={obj && !this.props.loading ? obj[1] : null}
              allowDuplicatedCategory={false}
            />
          )}
          <YAxis domain={[0, 50]} /> */}
          {/* <Tooltip content={<CustomTooltip/>}/> */}
          <Tooltip cursor={false} content={<CustomTooltip />} />
          {/* {this.props.isTooltip ? <Tooltip /> : null} */}
          {this.props.legend && (
            <Legend
              verticalAlign="bottom"
              height={36}
              content={this.renderLegend}

              //        payload={
              // obj.filter(
              //   item => item !== "name"
              //   ).map((item,index)=>(
              //     {
              //        id: item,
              //     type: "circle",
              //     value: `${item}`,
              //     color: COLORS[index],
              //     // height:30,
              //     // width:30,
              //     }

              //    ) )
              // }
            />
          )}
          {this.props.data.length < 30 ? (
            <Brush dataKey="name" height={30} stroke="#82b1ee" />
          ) : (
            <Brush
              startIndex={0}
              endIndex={31}
              dataKey="name"
              height={30}
              stroke="#82b1ee"
            />
          )}
        </LineChart>
      </div>
    );
  }
}

renderLineChart.defaultProps = {
  width: "100%",
  height: 300,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  lineType: "monotone",
  isCartReq: false,
  cartStroke: "#f0f0f0",
  cartDash: 5,
  isTooltip: true,
};

renderLineChart.propTypes = {
  data: propTypes.array.isRequired,
  lineColor: propTypes.array.isRequired,
};
export default withTheme(renderLineChart);
