import { put } from "redux-saga/effects";

import { ApiCaller, Utils } from "../../config";
import { DashboardAction } from "../actions";

export default class DashboardMiddleware {
  static *GetUsersCount({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/card?entity=users&start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(DashboardAction.getUsersCountSuccess(res.data.data.users));
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getUsersCountFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getUsersCountFailure());
    }
  }

  static *GetSubscriptionsCount({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/card?entity=subscriptions&start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(
          DashboardAction.getSubscriptionsCountSuccess(res.data.data.users)
        );
        if (cb) {
          cb();
        }
      } else {
        yield put(
          DashboardAction.getSubscriptionsCountFailure(res.data.message)
        );
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getSubscriptionsCountFailure());
    }
  }

  static *GetMediaCount({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/card?entity=media&start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(DashboardAction.getMediaCountSuccess(res.data.data.media));
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getMediaCountFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getMediaCountFailure());
    }
  }

  static *GetStreamsCount({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/card?entity=streams&start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(DashboardAction.getStreamsCountSuccess(res.data.data.users));
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getStreamsCountFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getStreamsCountFailure());
    }
  }

  static *GetAudioPurchases({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/card?entity=audio-purchases&start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(
          DashboardAction.getAudioPurchasesSuccess(res.data.data.audio)
        );
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getAudioPurchasesFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getAudioPurchasesFailure());
    }
  }

  static *GetVideoPurchases({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/card?entity=video-purchases&start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(
          DashboardAction.getVideoPurchasesSuccess(res.data.data.video)
        );
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getVideoPurchasesFailure(res.data.message));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getVideoPurchasesFailure());
    }
  }

  static *GetUserSignupStats({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/graph/users?start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(DashboardAction.getUserSignupStatsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getUserSignupStatsSuccess([]));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getMediaCountFailure());
    }
  }

  static *GetMediaTypeStats({ payload, cb }) {
    try {
      const { start_date, end_date, media_category = "" } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/chart/media-types?start_date=${start_date}&end_date=${end_date}&media_category=${media_category}`
      );
      if (res.status == 200) {
        yield put(DashboardAction.getMediaTypeStatsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getMediaTypeStatsSuccess([]));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getMediaTypeStatsFailure());
    }
  }

  static *GetMediaPlayCountStats({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/chart/media?start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(DashboardAction.getMediaPlayCountStatsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getMediaPlayCountStatsSuccess([]));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getMediaPlayCountStatsFailure());
    }
  }

  static *GetUserLocationStats({ payload, cb }) {
    try {
      const { start_date, end_date } = payload;
      let res = yield ApiCaller.Get(
        `/dashboard/chart/user-locations?start_date=${start_date}&end_date=${end_date}`
      );
      if (res.status == 200) {
        yield put(DashboardAction.getUserLocationStatsSuccess(res.data.data));
        if (cb) {
          cb();
        }
      } else {
        yield put(DashboardAction.getUserLocationStatsSuccess([]));
        Utils.showSnackBar({
          message: res.data.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getUserLocationStatsFailure());
    }
  }
}
