import {
  ADD_MEDIA,
  ADD_MEDIA_FAILURE,
  ADD_MEDIA_SUCCESS,
  DELETE_MEDIA,
  DELETE_MEDIA_FAILURE,
  DELETE_MEDIA_LYRICS,
  DELETE_MEDIA_LYRICS_FAILURE,
  DELETE_MEDIA_LYRICS_SUCCESS,
  DELETE_MEDIA_SUCCESS,
  EDIT_MEDIA,
  EDIT_MEDIA_FAILURE,
  EDIT_MEDIA_SUCCESS,
  GET_MEDIA_LISTING,
  GET_MEDIA_LISTING_FAILURE,
  GET_MEDIA_LISTING_SUCCESS,
} from "../constants";

export default class MediaAction {
  static getMediaListing(payload, cb) {
    return {
      type: GET_MEDIA_LISTING,
      payload,
      cb,
    };
  }
  static getMediaListingSuccess(payload) {
    return {
      type: GET_MEDIA_LISTING_SUCCESS,
      payload,
    };
  }
  static getMediaListingFailure() {
    return {
      type: GET_MEDIA_LISTING_FAILURE,
    };
  }

  static deleteMedia(id, cb) {
    return {
      type: DELETE_MEDIA,
      id,
      cb,
    };
  }
  static deleteMediaSuccess(payload) {
    return {
      type: DELETE_MEDIA_SUCCESS,
      payload,
    };
  }
  static deleteMediaFailure() {
    return {
      type: DELETE_MEDIA_FAILURE,
    };
  }

  static addMedia(data, cb) {
    return {
      type: ADD_MEDIA,
      data,
      cb,
    };
  }
  static addMediaSuccess(payload) {
    return {
      type: ADD_MEDIA_SUCCESS,
      payload,
    };
  }
  static addMediaFailure() {
    return {
      type: ADD_MEDIA_FAILURE,
    };
  }

  static editMedia(data, cb) {
    return {
      type: EDIT_MEDIA,
      data,
      cb,
    };
  }
  static editMediaSuccess(payload) {
    return {
      type: EDIT_MEDIA_SUCCESS,
      payload,
    };
  }
  static editMediaFailure() {
    return {
      type: EDIT_MEDIA_FAILURE,
    };
  }

  static deleteMediaLyrics(id, cb) {
    return {
      type: DELETE_MEDIA_LYRICS,
      id,
      cb,
    };
  }
  static deleteMediaLyricsSuccess(payload) {
    return {
      type: DELETE_MEDIA_LYRICS_SUCCESS,
      payload,
    };
  }
  static deleteMediaLyricsFailure() {
    return {
      type: DELETE_MEDIA_LYRICS_FAILURE,
    };
  }
}
