import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import { useNavigate } from "react-router";
import React, { useState } from "react";

import { Button, Text, TextField } from "../../components";
import { Colors, Images, Utils } from "../../config";
import LoginBox from "../../components/Layout/LoginBox";

import Fonts, {
  MontserratLight,
  MontserratMedium,
  PoppinsSemiBold,
} from "../../config/fonts";
import { AuthAction } from "../../store/actions";
import styles from "./styles";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const isLoading = useSelector((state) => state.auth.loginLoader);
  const [state, setState] = useState({
    email: "",
    password: "",
    emailErrMsg: "",
    passErrMsg: "",
    validEmail: true,
    validPass: true,
  });

  const signin = () => {
    const { email, password, validEmail, validPass } = state;
    if (!email)
      setState({
        ...state,
        emailErrMsg: "Please enter email.",
        validEmail: false,
      });
    else if (!validEmail)
      setState({ ...state, emailErrMsg: "Please enter valid email address." });
    else if (!password)
      setState({
        ...state,
        passErrMsg: "Please enter password",
        validPass: false,
      });
    else if (!validPass)
      setState({
        ...state,
        passErrMsg: "Minimum Password length should be atleast 6.",
      });
    else {
      setState({
        ...state,
        passErrMsg: "",
        emailErrMsg: "",
      });
      const payload = {
        email: state.email,
        password: state.password,
      };
      dispatch(
        AuthAction.SignIn(payload, () => {
          navigate("/dashboard");
        })
      );
    }
  };

  const handleForgotPassword = () => {
    const payload = {
      email: "nileur@nileurmusic.com",
      verification_purpose: "Account Recovery",
    };
    dispatch(AuthAction.ForgotPassword(payload, () => navigate("/verify-otp")));
  };

  const validateEmail = (email) => {
    let validEmail = Utils.emailRegex.test(String(email).toLowerCase());
    setState({ ...state, email, validEmail, emailErrMsg: "" });
  };

  const validatePass = (password) => {
    let validPass = password.length > 5;
    setState({ ...state, password, validPass, passErrMsg: "" });
  };

  return (
    <LoginBox>
      <Col lg={24} md={24} sm={24} xs={24} style={styles.flex}>
        <Text.Basic
          text="Sign in"
          fontWeight={"bold"}
          fontSize={isMobile ? 36 : isSmallMobile ? 28 : 24}
          textAlign="center"
          fontFamily={Fonts[MontserratMedium]}
          color={Colors.Black}
          className="mb-5"
        />
        <Text.Basic
          text="Welcome back! Login with your email address."
          fontSize={isMobile ? 16 : isSmallMobile ? 14 : 13}
          textAlign="center"
          fontFamily={Fonts[MontserratLight]}
          color={Colors.Black}
        />
      </Col>
      <Col span={24} className={!isMobile && "my-20"}>
        <TextField.Basic
          type={"email"}
          labelFontSize={isMobile ? 18 : 16}
          labelClassName={isMobile ? "mb-10" : "mb-5"}
          value={state.email}
          placeholder={"Email"}
          size={isMobile && "large"}
          color={Colors.inputText}
          onChange={(e) => validateEmail(e.target.value)}
          label="EMAIL ADDRESS"
          suffix={<img src={Images.User} alt="user" width={16} />}
          errorText={!state.validEmail && state.emailErrMsg}
        />
      </Col>
      <Col span={24} className={!isMobile && "mb-20"}>
        <TextField.Basic
          type={"password"}
          labelFontSize={isMobile ? 18 : 16}
          labelClassName={isMobile ? "mb-10" : "mb-5"}
          value={state.password}
          placeholder={"Password"}
          size={isMobile && "large"}
          color={Colors.inputText}
          onChange={(e) => validatePass(e.target.value)}
          label="PASSWORD"
          password
          errorText={!state.validPass && state.passErrMsg}
        />
      </Col>
      <Col span={24} className={!isMobile && "mb-20"}>
        <Row justify={"center"}>
          <Col span={24}>
            <Button.Basic
              text="Login"
              size={isMobile && "large"}
              onClick={signin}
              loading={isLoading}
              disabled={isLoading}
              buttonStyle={{
                width: "100%",
                height: "45px",
                backgroundColor: "#7836FF",
                color: "white",
                fontSize: "20px",
                fontFamily: Fonts[PoppinsSemiBold],
                borderRadius: "8px",
              }}
            />
          </Col>
        </Row>
      </Col>
      <Col span={24} style={styles.flex}>
        <Text.Basic
          text="Forgot Password?"
          fontSize={16}
          textAlign="center"
          cursor={"pointer"}
          fontFamily={Fonts[MontserratLight]}
          color={Colors.BlackText}
          onClick={handleForgotPassword}
        />
      </Col>
    </LoginBox>
  );
};

export default SignIn;
