import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import * as Icon from "react-icons/all";
import moment from "moment";
import React, { useEffect, useState } from "react";

import { Colors, Fonts } from "../../config";
import { Pagination, Switch, Table, Text, TextField } from "../../components";

import {
  MontserratBold,
  MontserratLight,
  MontserratRegular,
} from "../../config/fonts";
import { UserAction } from "../../store/actions";

function UserManagement() {
  const dispatch = useDispatch();
  const isSmallLaptop = useMedia({ minWidth: "1050px" });
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const users = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [location, setLocation] = useState("");
  const [state, setState] = useState({
    limit: 10,
    offset: 0,
    keyword: "",
    status: "",
    location_Id: "",
    location_type: "",
  });

  const handlePagination = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  const handleChangeStatus = (id) => {
    dispatch(
      UserAction.changeUserStatus(id, () => {
        dispatch(UserAction.getUserListing(state));
        setPage(1);
      })
    );
  };

  const handleSearchLocation = (e, value) => {
    setLocation(e);
    dispatch(UserAction.getUserLocations(e));
  };

  const handleClearLocation = () => {
    setState({
      ...state,
      location_Id: "",
      location_type: "",
    });
  };

  const handleSelectLocation = (e) => {
    const location = e.split("_");
    setLocation(location[0]);
    setPage(1);
    setState({
      ...state,
      offset: 0,
      location_Id: location[2],
      location_type: location[1],
    });
  };

  useEffect(() => {
    dispatch(UserAction.getUserListing(state));
  }, [page, state]);

  return (
    <div className="wrapper">
      <Row className="flex-row-space-center mx-15">
        <Col className="flex-column" xl={4} lg={5} md={5} sm={12} xs={24}>
          <div className="flex-row-align-center">
            <Text.Basic
              text="Users"
              color={Colors.Black}
              fontSize={isMobile ? 25 : isSmallMobile ? 20 : 18}
              fontWeight={600}
              fontFamily={Fonts[MontserratBold]}
              textAlign="left"
            />
            <span
              style={{
                width: "8px",
                height: "8px",
                background: "#7836FF",
                borderRadius: "50px",
                marginInline: "10px",
              }}
            ></span>
            <Text.Basic
              text={users?.count}
              color={Colors.Black}
              fontSize={18}
              fontWeight={400}
              fontFamily={Fonts[MontserratRegular]}
              textAlign="left"
            />
          </div>
          <Text.Basic
            text={`Deleted users: ${users?.deleted}`}
            color={Colors.LightGray}
            fontSize={isMobile ? 16 : isSmallMobile ? 14 : 12}
            fontWeight={300}
            fontFamily={Fonts[MontserratLight]}
            textAlign="left"
          />
          {!isMobile && (
            <Text.Basic
              text={`These are the users currently registered in the app`}
              color={Colors.LightGray}
              fontSize={isMobile ? 16 : isSmallMobile ? 14 : 12}
              fontWeight={300}
              fontFamily={Fonts[MontserratLight]}
              textAlign="left"
              className="mt-10"
            />
          )}
        </Col>
        <Col
          xxl={10}
          xl={15}
          lg={18}
          md={18}
          sm={24}
          xs={24}
          className={!isMobile && "mt-20"}
        >
          <Row className="flex-row-space-between">
            <Col
              xxl={9}
              xl={9}
              lg={9}
              md={9}
              sm={16}
              xs={24}
              className={!isSmallLaptop && "mb-10"}
            >
              <TextField.IconField
                className="listing-filter"
                size={isMobile && "large"}
                suffix={<Icon.FaSearch />}
                placeholder={"Search Users"}
                value={state.keyword}
                onChange={(e) =>
                  setState({
                    ...state,
                    keyword: e.target.value,
                  })
                }
              />
            </Col>
            <Col
              xxl={9}
              xl={9}
              lg={9}
              md={9}
              sm={16}
              xs={24}
              className={
                isMobile ? "user-location-search" : "user-location-search mb-10"
              }
            >
              <TextField.Autocomplete
                inputStyle={{ width: "100%" }}
                suffixIcon={<Icon.FaSearch size={16} color="black" />}
                size={isMobile && "large"}
                onClear={handleClearLocation}
                notFoundContent={"No location found"}
                value={location ? location : undefined}
                placeholder={"Search by location"}
                onSearch={handleSearchLocation}
                onSelect={handleSelectLocation}
                options={users?.locations}
              />
            </Col>
            <Col
              xxl={4}
              xl={4}
              lg={4}
              md={4}
              sm={8}
              xs={10}
              className={!isMobile && "mb-20"}
            >
              <TextField.SelectField
                inputStyle={{ width: "100%" }}
                className="listing-filter"
                size={isMobile && "large"}
                allowClear={false}
                onChange={(value) => {
                  if (value === "all") {
                    setState({
                      ...state,
                      status: "",
                    });
                  } else {
                    setState({
                      ...state,
                      status: value,
                    });
                  }
                }}
                value={state.status === "" ? "all" : state.status}
                options={[
                  {
                    label: "View All",
                    value: "all",
                  },
                  {
                    label: "Active",
                    value: "active",
                  },
                  {
                    label: "In-active",
                    value: "inactive",
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {isMobile && (
        <Row className="mb-20 mx-15 mt-10">
          <Col>
            <Text.Basic
              text={`These are the users currently registered in the app`}
              color={Colors.LightGray}
              fontSize={14}
              fontWeight={300}
              fontFamily={Fonts[MontserratLight]}
              textAlign="left"
            />
          </Col>
        </Row>
      )}
      <Table.Basic
        columns={[
          {
            title: "Full Name",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
              <span className="ml-10 table-cell" title={text}>
                {text.length > 15 ? `${text.slice(0, 15)}...` : text}
              </span>
            ),
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (text, record) => (
              <span className="table-cell" title={text}>
                {text.length > 15 ? `${text.slice(0, 15)}...` : text}
              </span>
            ),
          },
          {
            title: "Location",
            dataIndex: "city",
            key: "city",
            render: (text, record) => (
              <span className="table-cell" title={text}>
                {text !== null
                  ? text?.length > 15
                    ? `${text.slice(0, 15)}...`
                    : text
                  : "-"}
              </span>
            ),
          },
          {
            title: "Subscription",
            dataIndex: "subscription",
            key: "subscription",
            render: (text, record) => (
              <span
                className="table-cell"
                style={{
                  color:
                    text === "Subscribed"
                      ? Colors.BlueText
                      : text === "Free"
                      ? Colors.Black
                      : Colors.DarkGray,
                  textTransform: "capitalize",
                }}
              >
                {text}
              </span>
            ),
          },
          {
            title: "Expiry Date",
            dataIndex: "expiry_date",
            key: "expiry_date",
            render: (text, record) => (
              <span className="table-cell">
                {text === "The user has not activated their trial yet."
                  ? "-"
                  : moment(text).format("DD MMM, YYYY")}
              </span>
            ),
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text, record) => (
              <span
                className="table-cell"
                style={{
                  color: text === "active" ? Colors.BlueText : Colors.RedText,
                  backgroundColor:
                    text === "active" ? "#7836FF1A" : "#FF606B1A",
                  padding: "10px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
              >
                {text}
              </span>
            ),
          },
          {
            title: "",
            dataIndex: "",
            key: "",
            render: (text, record) => (
              <span>
                <Switch.Basic
                  checked={record.status !== "suspended" ? true : false}
                  onChange={(e) => handleChangeStatus(record?.id)}
                />
              </span>
            ),
          },
        ]}
        data={users?.users}
        loading={users.isLoading}
      />
      {users.count > 10 && (
        <Row justify={"center"} className="mt-10">
          <Col>
            <Pagination.Basic
              onChange={handlePagination}
              pageSize={10}
              current={page}
              total={users.count}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}

export default UserManagement;
