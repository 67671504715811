const Colors = {
  PlaceHolder: (opacity = "0.5") => `rgba(235, 235, 235, ${opacity})`,
  BlackOpacity: (opacity = "0.5") => `rgba(0, 0, 0, ${opacity})`,
  WhiteOpacity: (opacity = "0.5") => `rgba(255, 255, 255, ${opacity})`,
  Black: "#000000",
  Transparent: "transparent",
  Primary: "#d94826",
  Secondary: "#000000",
  LightBlue: "#EFF4F8",
  BlackText: "#05031A",
  BlackText2: "#1F1F1F",
  inputText: "#272727",
  LightGray: "#8A8A8A",
  BlueText: "#7836FF",
  RedText: "#FF606B",
  DarkGray: "#1E1E22",
  DarkGray2: "#4B4B4B",
  LightGray: "#989898",
  Yellow: "#F0AC02",
  Red: "#F7006B",
  White: "#ffffff",
  Text: "#ffffff",
  Danger: "#FF494C",
};

export default Colors;
