import AudioIcon from "../../assets/images/soundwave.png";
import AudioWhiteIcon from "../../assets/images/soundwaveWhite.svg";
import ButtonOn from "../../assets/images/button-on.png";
import DashboardIcon from "../../assets/images/dashboard.svg";
import DashboardSelectedIcon from "../../assets/images/dashboardSelected.png";
import DefaultAvatar from "../../assets/images/avatar.svg";
import DeleteIcon from "../../assets/images/delete-icon.png";
import EditIcon from "../../assets/images/edit-icon.png";
import Lock from "../../assets/images/lock.png";
import Logo from "../../assets/images/AppIcon.png";
import LogoWithText from "../../assets/images/AppIconText.png";
import MusicIcon from "../../assets/images/music.png";
import PlusIcon from "../../assets/images/plusIcon.svg";
import PopularSongPlaceholder from "../../assets/images/popularSongPlaceholder.png";
import PromoCodeIcon from "../../assets/images/promocode.png";
import PromoCodeSelectedIcon from "../../assets/images/promocodeSelected.png";
import ReportIcon from "../../assets/images/report.png";
import ReportSelectedIcon from "../../assets/images/reportSelected.svg";
import SecurityIcon from "../../assets/images/security.png";
import SecuritySelectedIcon from "../../assets/images/securitySelected.svg";
import SongCardIcon from "../../assets/images/songCardIcon.svg";
import SongIcon from "../../assets/images/song.png";
import SongPlaceholder from "../../assets/images/songPlaceholder.png";
import SongSelectedIcon from "../../assets/images/songSelected.svg";
import Upload from "../../assets/images/upload.png";
import User from "../../assets/images/user.png";
import UserCardIcon from "../../assets/images/userCardIcon.svg";
import UsersIcon from "../../assets/images/users.png";
import UsersSelectedIcon from "../../assets/images/usersSelected.svg";
import VideoIcon from "../../assets/images/playlist.png";

const Images = {
  Logo,
  LogoWithText,
  Lock,
  User,
  DashboardIcon,
  DashboardSelectedIcon,
  ReportIcon,
  ReportSelectedIcon,
  SecurityIcon,
  SecuritySelectedIcon,
  SongIcon,
  SongSelectedIcon,
  UsersIcon,
  UsersSelectedIcon,
  DefaultAvatar,
  MusicIcon,
  DeleteIcon,
  EditIcon,
  SongPlaceholder,
  ButtonOn,
  AudioIcon,
  AudioWhiteIcon,
  PopularSongPlaceholder,
  UserCardIcon,
  SongCardIcon,
  Upload,
  VideoIcon,
  PromoCodeIcon,
  PromoCodeSelectedIcon,
  PlusIcon,
};

export default Images;
