import { Col, Popconfirm, Row } from "antd";
import * as Icon from "react-icons/all";
import React, { useEffect, useState } from "react";

import {
  Button,
  Modal,
  Pagination,
  Switch,
  Table,
  Text,
  TextField,
} from "../../components";
import { Colors, Fonts, Images, Utils } from "../../config";

import { useDispatch, useSelector } from "react-redux";
import { useMedia } from "use-media";
import {
  MontserratBold,
  MontserratLight,
  MontserratMedium,
  MontserratRegular,
  PoppinsMedium,
} from "../../config/fonts";
import { PromoCodeAction } from "../../store/actions";

function PromoCode() {
  const dispatch = useDispatch();
  const isTablet = useMedia({ minWidth: "770px" });
  const isMobile = useMedia({ minWidth: "450px" });
  const isSmallMobile = useMedia({ minWidth: "350px" });
  const promoCode = useSelector((state) => state.promoCode);
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(1);
  const [listingState, setListingState] = useState({
    limit: 10,
    offset: 0,
    keyword: "",
  });
  const [state, setState] = useState({
    email: "",
    name: "",
    discount: "16.485%",
    promoCode: "",
    reward: "",
    details: "",
  });
  const [error, setError] = useState({
    emailError: "",
    nameError: "",
    promoCodeError: "",
    rewardError: "",
    detailsError: "",
  });

  const handleAddPromo = () => {
    const { email, name, reward, details, promoCode } = state;
    let { emailError, nameError, rewardError, detailsError, promoCodeError } =
      error;
    if (!name) {
      nameError = "Name cannot be left empty";
    }
    if (name && (name.length < 3 || name.length > 30)) {
      nameError = "Name must be minimum 3 and maximum 30 characters";
    }
    if (!email) {
      emailError = "Email cannot be left empty";
    }
    if (email && (email.length < 3 || email.length > 100)) {
      nameError = "Email must be minimum 3 and maximum 100 characters";
    }
    if (
      email &&
      email.length < 100 &&
      !Utils.emailRegex.test(String(email).toLowerCase())
    ) {
      emailError = "Please enter valid email address";
    }
    if (reward && (reward.length > 20)) {
      rewardError = "Reward must not be greater than 20 characters";
    }
    if (details && details.length > 120) {
      detailsError = "Details cannot be longer than 120 characters";
    }
    setError({
      nameError,
      emailError,
      rewardError,
      detailsError,
    });
    if (!nameError && !emailError && !rewardError && !detailsError) {
      const payload = {
        influencer_email: email,
        influencer_name: name,
        code: promoCode,
        reward: reward,
        details: details,
      };
      dispatch(
        PromoCodeAction.addPromoCode(payload, () => {
          setShowModal(false);
          dispatch(PromoCodeAction.getPromoCodes(listingState));
          setPage(1);
          setState({
            email: "",
            name: "",
            discount: "16.485%",
            promoCode: "",
            reward: "",
            details: "",
          });
        })
      );
    }
  };

  const handleChange = (value, field, errorField) => {
    if (field === "name") {
      setState({
        ...state,
        promoCode: `${value.split(" ").join("").slice(0, 3)}${
          state.discount[0]
        }`,
        name: value,
      });
    } else {
      setState({
        ...state,
        [field]: value,
      });
    }

    setError({
      ...error,
      [errorField]: "",
    });
  };

  const handlePagination = (pageNumber) => {
    let offset = (pageNumber - 1) * state.limit;
    setListingState({ ...state, offset: offset });
    setPage(pageNumber);
  };

  const handleChangeStatus = (id) => {
    dispatch(
      PromoCodeAction.changePromoCodeStatus(id, () => {
        dispatch(PromoCodeAction.getPromoCodes(listingState));
        setPage(1);
      })
    );
  };

  const handleDeletePromoCode = (id) => {
    dispatch(
      PromoCodeAction.deletePromoCode(id, () => {
        dispatch(PromoCodeAction.getPromoCodes(listingState));
        setPage(1);
      })
    );
  };

  useEffect(() => {
    dispatch(PromoCodeAction.getPromoCodes(listingState));
  }, [page]);

  return (
    <div className="wrapper">
      <Row className="flex-row-space-center mb-20 mx-15">
        <Col
          className="flex-column"
          xxl={6}
          xl={12}
          lg={18}
          md={18}
          sm={24}
          xs={24}
        >
          <div className="flex-row-align-center">
            <Text.Basic
              text="Promo Code"
              color={Colors.Black}
              fontSize={isMobile ? 25 : isSmallMobile ? 20 : 18}
              fontWeight={600}
              fontFamily={Fonts[MontserratBold]}
              textAlign="left"
            />
            <span
              style={{
                width: "8px",
                height: "8px",
                background: "#7836FF",
                borderRadius: "50px",
                marginInline: "10px",
              }}
            ></span>
            <Text.Basic
              text={promoCode?.count}
              color={Colors.Black}
              fontSize={18}
              fontWeight={400}
              fontFamily={Fonts[MontserratRegular]}
              textAlign="left"
            />
          </div>
          <Text.Basic
            text="These are the promo codes currently registered in the app"
            color={Colors.LightGray}
            fontSize={isMobile ? 16 : isSmallMobile ? 14 : 12}
            fontWeight={300}
            fontFamily={Fonts[MontserratLight]}
            textAlign="left"
          />
        </Col>
        <Col className={!isMobile && "mt-10"}>
          <Button.Basic
            icon={<img src={Images.PlusIcon} className="mr-5" />}
            size={"large"}
            buttonStyle={{
              backgroundColor: Colors.BlueText,
              borderRadius: "2px",
              boxShadow: "0px 3px 6px #00000029",
              color: Colors.White,
              height: "45px",
            }}
            text={"New Promo"}
            onClick={() => setShowModal(true)}
          />
        </Col>
      </Row>
      <Table.Basic
        columns={[
          {
            title: "Influencer Name",
            dataIndex: "name",
            key: "name",
            render: (text, record) => (
              <span className="ml-10 table-cell" title={text}>
                {text?.length > 15 ? `${text?.slice(0, 15)}...` : text}
              </span>
            ),
          },
          {
            title: "Promo Code",
            dataIndex: "code",
            key: "code",
            render: (text, record) => (
              <span className="table-cell" title={text}>
                {text?.length > 10 ? `${text?.slice(0, 10)}...` : text}
              </span>
            ),
          },
          {
            title: "Consumed",
            dataIndex: "consumed",
            key: "consumed",
            render: (text, record) => (
              <span className="table-cell">{text}</span>
            ),
          },
          {
            title: "Reward",
            dataIndex: "reward",
            key: "reward",
            render: (text, record) => (
              <span className="table-cell">
                {text === null
                  ? "-"
                  : text?.length > 10
                  ? `${text?.slice(0, 10)}...`
                  : text}
              </span>
            ),
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (text, record) => (
              <span
                className="table-cell cursor"
                title={text}
                onClick={() => {
                  navigator.clipboard.writeText(text);
                  Utils.showSnackBar({
                    message: "Email text copied successfully",
                  });
                }}
              >
                {text?.length > 10 ? `${text?.slice(0, 10)}...` : text}
              </span>
            ),
          },
          {
            title: "Details",
            dataIndex: "details",
            key: "details",
            render: (text, record) => (
              <span
                className="table-cell cursor"
                title={text}
                onClick={() => {
                  navigator.clipboard.writeText(text);
                  Utils.showSnackBar({
                    message: "Details text copied successfully",
                  });
                }}
              >
                {text === null
                  ? "-"
                  : text?.length > 15
                  ? `${text?.slice(0, 15)}...`
                  : text}
              </span>
            ),
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (text, record) => (
              <span className="flex-row-align-center">
                <Switch.Basic
                  checked={record.status == 1 ? true : false}
                  onChange={(e) => handleChangeStatus(record?.id)}
                  className="mr-5"
                />
                <Popconfirm
                  title="Delete promo code"
                  description="Are you sure you want to delete this promo code?"
                  onConfirm={() => handleDeletePromoCode(record?.id)}
                  icon={<Icon.CiCircleRemove size={20} color="red" />}
                  placement="topRight"
                  okText="Yes"
                  cancelText="No"
                >
                  <img src={Images.DeleteIcon} width={30} className="cursor" />
                </Popconfirm>
              </span>
            ),
          },
        ]}
        data={promoCode?.promoCodes}
        loading={promoCode?.isLoading}
      />
      {promoCode.count > 10 && (
        <Row justify={"center"} className="mt-10">
          <Col>
            <Pagination.Basic
              onChange={handlePagination}
              pageSize={10}
              current={page}
              total={promoCode?.count}
            />
          </Col>
        </Row>
      )}
      {showModal && (
        <Modal.Basic
          className="modal-lg"
          centered={true}
          title=""
          closable={false}
          open={showModal}
          handleCancel={() => {
            setShowModal(false);
            setState({
              email: "",
              name: "",
              discount: "16.485%",
              promoCode: "",
              reward: "",
              details: "",
            });
            setError({
              emailError: "",
              nameError: "",
              promoCodeError: "",
              rewardError: "",
              detailsError: "",
            });
          }}
        >
          <Row justify={"space-between"} className="mb-20">
            <Col>
              <Text.Basic
                color={Colors.Black}
                fontSize={isMobile ? 22 : isSmallMobile ? 20 : 18}
                fontWeight={600}
                fontFamily={Fonts[MontserratBold]}
                text={"Add Promo Code"}
                textAlign="left"
              />
            </Col>
            <Col>
              <Icon.MdClose
                color={Colors.Black}
                fontSize={24}
                cursor={"pointer"}
                onClick={() => {
                  setShowModal(false);
                  setState({
                    email: "",
                    name: "",
                    discount: "16.485%",
                    promoCode: "",
                    reward: "",
                    details: "",
                  });
                  setError({
                    emailError: "",
                    nameError: "",
                    promoCodeError: "",
                    rewardError: "",
                    detailsError: "",
                  });
                }}
              />
            </Col>
          </Row>
          <Row justify="space-between" className="mb-20">
            <Col xxl={11} xl={11} lg={11} md={11} sm={22} xs={22}>
              <TextField.Basic
                label="Email Address:*"
                labelFontWeight={600}
                labelFontFamily={Fonts[MontserratMedium]}
                value={state.email}
                onChange={(e) =>
                  handleChange(e.target.value, "email", "emailError")
                }
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                placeholder="johndoe@example.com"
                errorText={error.emailError && error.emailError}
              />
            </Col>
            <Col
              xxl={11}
              xl={11}
              lg={11}
              md={11}
              sm={22}
              xs={22}
              className={isMobile ? "flex-column" : "flex-column mt-20"}
            >
              <TextField.Basic
                label="Name:*"
                labelFontWeight={600}
                labelFontFamily={Fonts[MontserratMedium]}
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                value={state.name}
                onChange={(e) =>
                  handleChange(e.target.value, "name", "nameError")
                }
                placeholder="John Doe"
                errorText={error.nameError && error.nameError}
              />
            </Col>
          </Row>
          <Row justify="space-between" className="mb-20">
            <Col xxl={11} xl={11} lg={11} md={11} sm={22} xs={22}>
              <TextField.Basic
                label="Discount Percent:"
                labelFontWeight={600}
                disabled={true}
                value={state.discount}
                labelFontFamily={Fonts[MontserratMedium]}
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
              />
            </Col>
            <Col
              xxl={11}
              xl={11}
              lg={11}
              md={11}
              sm={22}
              xs={22}
              className={isMobile ? "flex-column" : "flex-column mt-20"}
            >
              <TextField.Basic
                label="Promo Code:"
                labelFontWeight={600}
                labelFontFamily={Fonts[MontserratMedium]}
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                value={state.promoCode}
                onChange={(e) =>
                  handleChange(e.target.value, "promoCode", "promoCodeError")
                }
                placeholder="Enter promo code"
                errorText={error.promoCodeError && error.promoCodeError}
              />
            </Col>
          </Row>
          <Row justify="space-between" className="mb-20">
            <Col xxl={11} xl={11} lg={11} md={11} sm={22} xs={22}>
              <TextField.Basic
                label="Reward (give to influencer):"
                labelFontWeight={600}
                labelFontFamily={Fonts[MontserratMedium]}
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                value={state.reward}
                onChange={(e) =>
                  handleChange(e.target.value, "reward", "rewardError")
                }
                placeholder="Yes"
                errorText={error.rewardError && error.rewardError}
              />
            </Col>
            <Col
              xxl={11}
              xl={11}
              lg={11}
              md={11}
              sm={22}
              xs={22}
              className={isMobile ? "flex-column" : "flex-column mt-20"}
            >
              <TextField.Basic
                label="Details:"
                labelFontWeight={600}
                labelFontFamily={Fonts[MontserratMedium]}
                labelFontSize={isMobile ? 18 : 16}
                labelClassName={isMobile ? "mb-10" : "mb-5"}
                size={isMobile && "large"}
                value={state.details}
                maxLength={120}
                onChange={(e) =>
                  handleChange(e.target.value, "details", "detailsError")
                }
                placeholder="In publishing and graphics"
                errorText={error.detailsError && error.detailsError}
              />
              <Text.Basic
                text={`(${120 - state.details.length} characters)`}
                color={Colors.RedText}
                fontSize={14}
                fontWeight={400}
                fontFamily={Fonts[MontserratRegular]}
                className="flex-row-end"
              />
            </Col>
          </Row>
          <Row justify={"center"} className="mb-10">
            <Col>
              <Text.Basic
                text={
                  "*4.69 makes the value of 3.9 considering the discount percentage of 16.485"
                }
                color={Colors.Black}
                fontSize={14}
                fontWeight={600}
                fontFamily={Fonts[MontserratMedium]}
              />
            </Col>
          </Row>
          <Row justify={"center"} className="my-20 mt-20">
            <Col span={12}>
              <Button.Basic
                text="Submit"
                size={"large"}
                onClick={handleAddPromo}
                buttonStyle={{
                  width: "100%",
                  height: "50px",
                  backgroundColor: Colors.BlueText,
                  color: Colors.White,
                  fontSize: "16px",
                  fontFamily: Fonts[PoppinsMedium],
                  borderRadius: "10px",
                  border: "none",
                }}
              />
            </Col>
          </Row>
        </Modal.Basic>
      )}
    </div>
  );
}

export default PromoCode;
