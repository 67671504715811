import Axios from "axios";

import { AuthAction } from "../../store/actions";
import Store from "../../store/index";
import variables from "../constants";

const { BASE_URL } = variables;

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  ({ response }) => {
    if (response.status == 401) {
      try {
        localStorage.removeItem("authUser");
        Store.dispatch(AuthAction.signInSuccess());
        window.location.reload();
      } catch (err) {}
    }
    return response;
  }
);

Axios.interceptors.request.use(
  function (config) {
    if (localStorage.getItem("authUser")) {
      config.headers = {
        ...config?.headers,
        Authorization: `Bearer ${
          JSON.parse(localStorage.getItem("authUser"))?.secret
        }`,
      };
      config.timeout = 600000;
      return config;
    } else {
      config.timeout = 600000;
      return config;
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);
const CancelToken = Axios.CancelToken;

export default class ApiCaller {
  static Get = (endPoint = "", headers = {}, data = {}) => {
    return Axios.get(
      `${BASE_URL}${endPoint}`,
      {
        headers,
      },
      data
    )
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Post = (endPoint = "", body = {}, headers = {}) => {
    return Axios.post(`${BASE_URL}${endPoint}`, body, {
      headers,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Put = (endPoint = "", body = {}, headers = {}) => {
    return Axios.put(`${BASE_URL}${endPoint}`, body, {
      headers,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };

  static Delete = (endPoint = "", headers = {}) => {
    return Axios.delete(`${BASE_URL}${endPoint}`, {
      headers,
    })
      .then((res) => res)
      .catch((err) => err.response);
  };
}
