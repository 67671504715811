import {
  DELETE_FEEDBACK,
  DELETE_FEEDBACK_FAILURE,
  DELETE_FEEDBACK_SUCCESS,
  GET_FEEDBACKS,
  GET_FEEDBACKS_FAILURE,
  GET_FEEDBACKS_SUCCESS,
  REPLY_FEEDBACK,
  REPLY_FEEDBACK_FAILURE,
  REPLY_FEEDBACK_SUCCESS,
} from "../constants";

export default class FeedbackAction {
  static getFeedbacks(payload, cb) {
    return {
      type: GET_FEEDBACKS,
      payload,
      cb,
    };
  }
  static getFeedbacksSuccess(payload) {
    return {
      type: GET_FEEDBACKS_SUCCESS,
      payload,
    };
  }
  static getFeedbacksFailure() {
    return {
      type: GET_FEEDBACKS_FAILURE,
    };
  }

  static deleteFeedback(id, cb) {
    return {
      type: DELETE_FEEDBACK,
      id,
      cb,
    };
  }
  static deleteFeedbackSuccess(payload) {
    return {
      type: DELETE_FEEDBACK_SUCCESS,
      payload,
    };
  }
  static deleteFeedbackFailure() {
    return {
      type: DELETE_FEEDBACK_FAILURE,
    };
  }

  static replyFeedback(payload, cb) {
    return {
      type: REPLY_FEEDBACK,
      payload,
      cb,
    };
  }
  static replyFeedbackSuccess(payload) {
    return {
      type: REPLY_FEEDBACK_SUCCESS,
      payload,
    };
  }
  static replyFeedbackFailure() {
    return {
      type: REPLY_FEEDBACK_FAILURE,
    };
  }
}
