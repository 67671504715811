import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import React from "react";

import AppRoutes from "./AppRoutes.jsx";
import AuthRoutes from "./AuthRoutes.jsx";

const AllRoutes = () => {
  const isLoggedIn = useSelector((state) => state?.auth?.isLoggedIn);
  const navigate = useNavigate();
  if (window.location.pathname === "/login" && isLoggedIn) {
    navigate("/dashboard");
  }

  if (isLoggedIn) {
    return <AppRoutes />;
  } else {
    return <AuthRoutes />;
  }
};

export default AllRoutes;
